import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faHome,
  faRunning,
  faStar,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
//important for getting nice style.
import "image-upload-react/dist/index.css";

const smallFontSize = "12px";
const mediumFontSize = "16px";
const bigFontSize = "18px";
const veryBigFontSize = "24px";

class BottomMenu extends React.Component {
  state = {
    recensionePage: "1",
    passo: 12.7,
    imageSrc: "",
  };

  componentDidUpdate(oldProps, oldState) {
    if (
      this.state.recensionePage !== oldState.recensionePage &&
      this.state.recensionePage === "4"
    ) {
      setTimeout(() => {
        this.goToPage("Home");
      }, 3600);
    }
  }

  render() {
    return (
      <Fragment>
        <div
          style={{
            width: "calc(100vw - 48px)",
            height: 48,
            backgroundColor: "#fefefe",
            borderTop: "1px solid #ddd",
            position: "fixed",
            bottom: this.props.position,
            transition: "600ms ease-in-out",
            left: 0,
            display: "flex",
            alignItems: "center",
            padding: "0 24px",
            overflow: "auto",
            justifyContent: "space-evenly",
          }}
          // className={!this.props.showBottom ? "footer showFooter" : "footer"}
          className="footer"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              fontSize: 12,
              color: "rgb(149, 214, 10)",
            }}
          >
            <FontAwesomeIcon
              icon={faHome}
              style={{
                height: 16,
                width: 16,
                // marginBottom: 4,
                color: "rgb(149, 214, 10)",
              }}
            />
            Home
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              fontSize: 12,
              color: "#999",
            }}
          >
            <FontAwesomeIcon
              icon={faStar}
              style={{
                height: 16,
                width: 16,
                // marginBottom: 4,
                color: "#aaa" }}
            />
            Preferiti
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              fontSize: 12,
              color: "#999",
            }}
          >
            <FontAwesomeIcon
              icon={faUser}
              style={{
                height: 16,
                width: 16,
                // marginBottom: 4,
                color: "#aaa" }}
            />
            Profilo
          </div>
        </div>
      </Fragment>
    );
  }
}

export default BottomMenu;
