import React, { Fragment } from "react";
import "../style/LandingStyle.css";
import posizione from "../img/searchResult/iconfinder_icons_pin_1564524 (2).png";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRunning,
  faStar,
  faPlus,
  faPen,
  faCalendar,
  faThumbsUp,
  faHeart,
  faUserAlt,
  faShareAlt
} from "@fortawesome/free-solid-svg-icons";
import DotFeedback from "./DotFeedback";
import userImg from "../img/landing/user_img.jpg";
import trail from "../img/home/runType/Trail_cerchio.svg";
import road from "../img/home/runType/Road_cerchio.svg";
import halfMarathon from "../img/home/runType/HalfMarathon_cerchio.svg";
import marathon from "../img/home/runType/Marathon_cerchio.svg";
import ultra from "../img/home/runType/Ultra_cerchio.svg";
import all from "../img/home/runType/All_cerchio.svg";
import StarRatings from "react-star-ratings";
import cross from "../img/searchResult/iconfinder_Artboard_46_5208412.png";

const smallFontSize = "12px";
const mediumFontSize = "16px";
const bigFontSize = "18px";
const veryBigFontSize = "24px";

const priceData = [
  {
    data: "02/04/2021",
    costo: 30,
  },
  {
    data: "06/04/2021",
    costo: 35,
  },
  {
    data: "08/04/2021",
    costo: 45,
  },
];

class RaceDetail extends React.Component {
  state = {
    like: false,
    run: false,
    pref: false,
    love: false,
    voteDisplay: false,
    starRating: 3,
    expandPrice: false,
    share:false,
  };

  goToRace = () => {
    window.location.href = "/Race/" + this.props.race.id;
  };

  goToReview = () => {
    window.location.href = "/Recensione";
  };

  runAndVote = () => {
    this.setState({
      run: !this.state.run,
      voteDisplay: true,
    });
  };

  renderTypeIcon = () => {
    const { race } = this.props;
    console.log('race', race)
    if (
      race["nome"].toLowerCase().includes("trail") ||
      !!race["tipo1"] && race["tipo1"].toLowerCase().includes("trail")
    ) {
      return trail;
    } else if (
      race["nome"].toLowerCase().includes("ultra") ||
      !!race["tipo1"] && race["tipo1"].toLowerCase().includes("ultra")
    ) {
      return ultra;
    } else if (
      race["nome"].toLowerCase().includes("vertical") ||
      !!race["tipo1"] && race["tipo1"].toLowerCase().includes("vertical")
    ) {
      return road;
    } else if (
      race["nome"].toLowerCase().includes("marathon") ||
      !!race["tipo1"] && race["tipo1"].toLowerCase().includes("marathon")
    ) {
      return marathon;
    } else if (
      race["nome"].toLowerCase().includes("half") ||
      !!race["tipo1"] && race["tipo1"].toLowerCase().includes("half")
    ) {
      return halfMarathon;
    } else {
      return all;
    }
  };
  changeRating(newRating, name) {
    this.setState({
      rating: newRating,
    });
    setTimeout(() => {
      this.setState({
        voteDisplay: false,
      });
    }, 1200);
  }

  render() {
    const rating = Math.floor(Math.random() * 100);
    const { race, run, pref } = this.props;

    return (
      <Fragment>
        {this.state.voteDisplay && this.state.run && (
          <div
            style={{
              position: "absolute",
              width: document.getElementById("race-detail").offsetWidth,
              height: document.getElementById("race-detail").offsetHeight,
              backgroundColor: "rgba(46,46,46,0.4)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={cross}
              alt=""
              style={{
                position: "absolute",
                right: 0,
                top: 6,
                margin: 16,
                height: "24px",
                width: "24px",
                objectFit: "cover",
                paddingTop: "0vh",
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({ voteDisplay: !this.state.voteDisplay })
              }
            />

            <div
              style={{
                backgroundColor: "#f5f5f5",
                padding: 16,
                borderRadius: 16,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ marginBottom: 8 }}>Lascia una recensione!</div>
              <StarRatings
                rating={this.state.rating}
                starRatedColor="rgb(149, 214, 10)"
                starHoverColor="rgb(149, 214, 10)"
                changeRating={this.changeRating.bind(this)}
                numberOfStars={5}
                name="rating"
                starDimension={"18px"}
                starSpacing={"1px"}
              />
            </div>
          </div>
        )}
        <div
          id="race-detail"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
            <div
              style={{
                // fontSize: veryBigFontSize,
                fontWeight: "bold",
                fontSize: 16,
                textTransform: 'capitalize',
                paddingTop: 12,
              }}
              onClick={this.goToRace}
            >
              {race["nome"]}
            </div>
            {/* {this.props.homeland && */}
              <div style={{paddingTop:"12px", paddingLeft:4, minWidth:81, display:"flex"}}>
                <div onClick={() => this.setState({pref:!this.state.pref})}>
                  <FontAwesomeIcon
                    icon={faStar}
                    style={{
                      height: 22,
                      width: 22,
                      color: this.state.pref ? "rgb(149, 214, 10)" : "#ccc",
                    }}
                    className={
                      this.state.pref ? "iconSingleResult" : "iconSingleResult"
                    }
                  />
                </div>
                <div onClick={() => this.setState({share:!this.state.share})} style={{paddingLeft: 4}}>
                  <FontAwesomeIcon
                    icon={faShareAlt}
                    style={{
                      height: 22,
                      width: 22,
                      color: this.state.share ? "rgb(149, 214, 10)" : "#ccc",
                    }}
                    className={
                      this.state.pref ? "iconSingleResult" : "iconSingleResult"
                    }
                  />
                </div>
                <div onClick={() => this.runAndVote()} style={{paddingLeft: 4}}>
                  <FontAwesomeIcon
                    icon={faRunning}
                    style={{
                      height: 24,
                      width: 24,
                      paddingRight: "0.0vw",
                      color: this.state.run ? "rgb(149, 214, 10)" : "#ccc",
                    }}
                    className={
                      this.state.run ? "iconSingleResult" : "iconSingleResult"
                    }
                  />
                </div>
              </div>
            {/* } */}
          </div>
          {/* {this.props.homeland && */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: 0,
              fontWeight: "bold",
              fontSize: 14,
              color:"grey",
            }}
          >
            <div>
              {!isNaN(Number(race["Lunghezza (km)"]))
                ? `${race["Lunghezza (km)"]} km`
                : "Err km"}
            </div>
            <div style={{paddingLeft: 16}}>
              {!!race["Dislivello (D+)"] &&
              !isNaN(Number(race["Dislivello (D+)"]))
                ? race["Dislivello (D+)"]
                : "Err"}{" "}
              D+
            </div>
          </div>
          {/* } */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: 8,
            }}
          >
            <div style={{ flex: 2, display: "flex", alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 48,
                  width: 48,
                  backgroundColor: "rgb(149, 214, 10)",
                  borderRadius: 8,
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {(race.rating.general / 10).toFixed(1)}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: 8,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <DotFeedback value={race.rating.general} />
                </div>
                <a
                  style={{
                    color: "rgb(149, 214, 10)",
                    textDecoration: "underline",
                    fontSize: 14,
                  }}
                >
                  {`${race.numReviews} recensioni`}
                </a>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <img
                src={this.renderTypeIcon()}
                alt=""
                className="runType"
                style={{ height: 48, width: 48 }}
              />
            </div>
          </div>
          {/* {!this.props.homeland &&
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: 8,
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              <div>
                {!isNaN(Number(race["Lunghezza (km)"]))
                  ? `${race["Lunghezza (km)"]} km`
                  : "Err km"}
              </div>
              <div style={{paddingLeft: 16}}>
                {!!race["Dislivello (D+)"] &&
                !isNaN(Number(race["Dislivello (D+)"]))
                  ? race["Dislivello (D+)"]
                  : "Err"}{" "}
                D+
              </div>
            </div>
          } */}

          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div style={{ flex: 2, display: "flex", flexDirection: "column" }}>
              <a
                target="_blank"
                href={`https://maps.google.com/?q=${race["Luogo"]},${race["Provincia"]},${race["Regione"]},${race["Paese"]}`}
                style={{ textDecoration: "none" }}
              >
                <div
                  style={{
                    display: "flex",
                    paddingTop: 16,
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img
                      src={posizione}
                      alt=""
                      style={{
                        position: "relative",
                        height: "16px",
                        width: "16px",
                      }}
                    />
                  </div>
                  <div style={{ paddingLeft: 12 }}>
                    <div
                      style={{
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "black",
                        textDecorationColor: "black",
                      }}
                    >
                      {race["Luogo"] + ", " + race["Paese"]}
                    </div>
                    <div style={{ fontSize: 12, color: "#999" }}>
                      100 km dalla tua posizione
                    </div>
                  </div>
                </div>
              </a>

              <div
                style={{
                  display: "flex",
                  paddingTop: 16,
                  alignItems: "center",
                }}
              >
                <div>
                  <FontAwesomeIcon
                    icon={faCalendar}
                    style={{ height: 16, width: 16 }}
                  />
                </div>
                <div style={{ paddingLeft: 12 }}>
                  <div style={{ fontSize: 14 }}>
                    {moment(race["Data"]).format("DD/MM/YYYY")}
                  </div>
                  <div style={{ fontSize: 12, color: "#999" }}>
                    Vedi altre gare nella <u>stessa data</u>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                flex: 1,
                fontSize: 14,
                display: "flex",
                flexDirection: "column",
                paddingTop: 16,
              }}
            >
              {/* <div
                onClick={() => this.setState({ showPrices: true })}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "right",
                }}
                onClick={() =>
                  this.setState({ expandPrice: !this.state.expandPrice })
                }
              >
                <div style={{ textDecoration: "underline" }}>Costo gara</div>
                <div style={{ fontSize: 24 }}>€ {race["Costo"] || 30}</div>
              </div> */}

              <div
                style={{
                  fontSize: 12,
                  color: "red",
                  paddingLeft: 12,
                  paddingTop: 8,
                  textAlign: "right",
                }}
              >
                Rimangono <u>4 pettorali</u> al prezzo Runvisor
              </div>
              {this.state.expandPrice && (
                <div
                  style={{
                    position: "absolute",
                    top: document.getElementById("race-detail").offsetTop,
                    left: document.getElementById("race-detail").offsetLeft,
                    width: document.getElementById("race-detail").offsetWidth,
                    height: document.getElementById("race-detail").offsetHeight,
                    backgroundColor: "rgba(46,46,46,0.4)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#f5f5f5",
                      padding: 16,
                      borderRadius: 16,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={cross}
                      alt=""
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 6,
                        margin: 16,
                        height: "24px",
                        width: "24px",
                        objectFit: "cover",
                        paddingTop: "0vh",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.setState({ expandPrice: !this.state.expandPrice })
                      }
                    />

                    {priceData.map((item) => (
                      <div>
                        <div style={{ fontSize: "18px", lineHeight: "24px" }}>
                          Fino al {item.data} € {item.costo}{" "}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* <div style={{flex: 1, display: 'flex', fontSize: 12, alignItems: 'center'}}>
                  <div>
                    <img src={userImg} style={{height: 32, width: 32, borderRadius: 50}} />
                  </div>
                  <div style={{paddingLeft: 12}}>
                    <div style={{textDecoration: 'underline'}}>14 amici</div>
                    <div>parteciperanno</div>
                  </div>
                </div> */}
            </div>
          </div>
          {/* {!this.props.homeland &&
            <div
              style={{display: "flex", flexDirection: "row", paddingTop: 18}}
            >
              <div onClick={() => this.setState({like: !this.state.like})}>
                <FontAwesomeIcon
                  icon={faThumbsUp}
                  style={{
                    height: "25px",
                    width: "25px",
                    paddingRight: "0.5vw",
                    color: this.state.like ? "rgb(149, 214, 10)" : "#ccc",
                  }}
                  className={
                    this.state.like ? "iconSingleResult" : "iconSingleResult"
                  }
                />
              </div>
              <div
                onClick={() => this.setState({pref: !this.state.pref})}
                style={{paddingLeft: 8}}
              >
                <FontAwesomeIcon
                  icon={faStar}
                  style={{
                    height: "25px",
                    width: "25px",
                    color: this.state.pref ? "rgb(149, 214, 10)" : "#ccc",
                  }}
                  className={
                    this.state.pref ? "iconSingleResult" : "iconSingleResult"
                  }
                />
              </div>
              <div
                onClick={() => this.setState({love: !this.state.love})}
                style={{paddingLeft: 8}}
              >
                <FontAwesomeIcon
                  icon={faHeart}
                  style={{
                    height: "25px",
                    width: "25px",
                    color: this.state.love ? "rgb(149, 214, 10)" : "#ccc",
                  }}
                  className={
                    this.state.love ? "iconSingleResult" : "iconSingleResult"
                  }
                />
              </div>
              <div onClick={() => this.runAndVote()} style={{paddingLeft: 8}}>
                <FontAwesomeIcon
                  icon={faRunning}
                  style={{
                    height: "25px",
                    width: "25px",
                    paddingRight: "0.5vw",
                    color: this.state.run ? "rgb(149, 214, 10)" : "#ccc",
                  }}
                  className={
                    this.state.run ? "iconSingleResult" : "iconSingleResult"
                  }
                />
              </div>
            </div>
          } */}
        </div>
      </Fragment>
    );
  }
}

export default RaceDetail;

const renderMonth = (month) => {
  console.log("month", month);
  switch (month) {
    case 0:
      return "GEN";
    case 1:
      return "FEB";
    case 2:
      return "MAR";
    case 3:
      return "APR";
    case 4:
      return "MAG";
    case 5:
      return "GIU";
    case 6:
      return "LUG";
    case 7:
      return "AGO";
    case 8:
      return "SET";
    case 9:
      return "OTT";
    case 10:
      return "NOV";
    case 11:
      return "DIC";
  }
};

const renderDate = (date) => {
  console.log("date", date);
  if (String(date).length === 1) {
    return `0${date}`;
  } else {
    return date;
  }
};
