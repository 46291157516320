import React, { Fragment } from "react";
import HamburgerMenu from "react-hamburger-menu";
import BurgerMenu from "./BurgerMenu";
import runvisor from "../img/loghi/loghi/logo_3a.jpg";

import "@animated-burgers/burger-squeeze/dist/styles.css";
import "../style/hamburgers.css";
import Burger from "./Burger";

class Header extends React.Component {
  state = {
    previousPage: this.props.previousPage,
    nextPage: this.props.nextPage,
    inProp: true,
    tickAppear: "hiddenElement",
    isOpen: false,
    height: 0,
  };

  handleClick() {
    this.setState({
      open: !this.state.open,
    });
  }
  setIsOpen = (isOpen) => {
    this.setState({ isOpen });
  };

  goToPage = (page) => {
    setTimeout(() => {
      // this.props.history.push("/" + page);
      this.props.history.push({
        pathname: '/'+ page,
        state: {path:this.props.match.url,
          name: ""
        }
      })
    }, 100);
  };

  render() {
    return (
      <Fragment>
        <div style={{ position: "absolute" }}>
          <div
            className={
              this.state.isOpen
                ? "headerContainer noShadow"
                : "headerContainer "
            }
          >
            <img
              src={runvisor}
              className="runvisor"
              onClick={() => this.goToPage("Home")}
            />
            <Burger setIsOpen={this.setIsOpen} />
          </div>
          <div
            className={
              this.state.isOpen
                ? "headerItemContainer "
                : "headerItemContainer change "
            }
            style={{
              top: "51px",
              backgroundColor: "white",
              // boxShadow:"0 4px 12px rgba(0,0,0,0.4)",
              position: "fixed",
              zIndex: "2000",
            }}
          >
            <div
              className="menuItem"
              style={{ paddingTop: "5.5vh" }}
              onClick={() => this.goToPage("Home")}
            >
              Home
            </div>
            <div
              className="menuItem"
              style={{ paddingTop: "1.2vh" }}
              onClick={() => this.goToPage("Recensione")}
            >
              Scrivi Recensione
            </div>
            <div
              className="menuItem"
              style={{ paddingTop: "1.2vh", paddingBottom: "2vh" }}
              onClick={() => this.goToPage("LoginRegister")}
            >
              Log In / Sign Up
            </div>
            <div
              style={{ display: "flex", width: "100vw", alignItems: "center" }}
            >
              <hr
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "96vw",
                  border: "1px solid lightgrey",
                  borderRadius: "20px",
                  marginTop: "1.5vh",
                  marginBottom: "2.5vh",
                }}
              />
            </div>
            <div
              className="menuItem"
              style={{ paddingTop: "2vh" }}
              onClick={() => this.goToPage("SearchResult")}
            >
              Search
            </div>
            <div
              className="menuItem"
              style={{ paddingTop: "1.2vh" }}
              onClick={() => this.goToPage("Recensione")}
            >
              Reviews
            </div>
            <div className="menuItem" style={{ paddingTop: "1.2vh" }}>
              News
            </div>
            <div
              className="menuItem"
              style={{ paddingTop: "1.2vh", paddingBottom: "2vh" }}
            >
              About
            </div>
            <div
              style={{ display: "flex", width: "100vw", alignItems: "center" }}
            >
              <hr
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "96vw",
                  border: "1px solid lightgrey",
                  borderRadius: "20px",
                  marginTop: "1.5vh",
                  marginBottom: "2.5vh",
                }}
              />
            </div>
            <div className="menuItem" style={{ paddingTop: "2vh" }}>
              Contact
            </div>
            <div
              className="menuItem"
              style={{ paddingTop: "1.2vh", paddingBottom: "5.5vh" }}
            >
              Help
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Header;
