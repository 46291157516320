import React, { Fragment } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

const smallFontSize = "12px";
const mediumFontSize = "16px";
const bigFontSize = "18px";
const veryBigFontSize = "24px";

const formPadding = "3.5vw";
const paddingTopRadioButton = "2.6vh";
const paddingLeftRadioButton = "2vw";
const paddingTopTitleRadio = "2.6vh";

const data = [
  {
    name: "Mese",
  },
  {
    name: "Trimestre",
  },
  {
    name: "Semestre",
  },
  {
    name: "Anno",
  },
];
const landscape = [
  {
    name: "Mare",
  },
  {
    name: "Montagna",
  },
  {
    name: "Lago",
  },
  {
    name: "Deserto",
  },
  {
    name: "Vulcano",
  },
];
const style = [
  {
    name: "A Tappe",
  },
  {
    name: "In Team",
  },
  {
    name: "Notturna",
  },
  {
    name: "Accesso UTMB",
  },
];
const feedback = [
  {
    name: "3",
  },
  {
    name: "4",
  },
  {
    name: "5",
  },
];
const orderType = [
  {
    name: "Data crescente",
    value: "date",
  },
  {
    name: "Lunghezza crescente",
    value: "distance",
  },
  {
    name: "Dislivello",
    value: "elevation",
  },
  {
    name: "Giudizio dei Runners",
    value: "rating",
  },
  {
    name: "Prezzo",
    value: "price",
  },
  {
    name: "Pettorali Runvisor",
    value: "numPettorali",
  },
  {
    name: "Numero di partecipanti",
    value: "numReviews",
  },
];

const calculateDeltaDate = (value) => {
  switch (value) {
    case "Mese":
      return 30;
    case "Trimestre":
      return 90;
    case "Semestre":
      return 180;
    case "Anno":
      return 365;
    default:
      return 365;
  }
};
class FilterOrderField extends React.Component {
  state = {
    distance: { min: 0, max: 500 },
    elevation: { min: 0, max: 10000 },
    date: "",
    landscapeFlter: "",
    styleFilter: "",
    feedbackFilter: "",
    orderFilter: "",
    sort: "date",
  };

  handleClickRadioButton = (field, value) => {
    this.setState({ [field]: value });
    if (!!this.props.handleChangeField) {
      if (field === "date") {
        let delta = calculateDeltaDate(value);
        this.props.handleChangeField(field, delta);
      } else {
        this.props.handleChangeField(field, value.toLowerCase());
      }
    }
  };

  onclickRadioButtonDate = (value) => {
    this.setState({ dateFilter: value });
  };

  onclickRadioButtonLandscape = (value) => {
    this.setState({ landscapeFilter: value });
  };

  onclickRadioButtonStyle = (value) => {
    this.setState({ styleFilter: value });
  };

  onclickRadioButtonFeedback = (value) => {
    this.setState({ feedbackFilter: value });
  };

  onclickRadioButtonOrder = (value) => {
    this.setState({ orderFilter: value });
  };

  handleRange = (field, val) => {
    this.setState({
      [field]: val,
    });
    if (!!this.props.handleChangeField) {
      this.props.handleChangeField(field, val);
    }
  };

  render() {
    // console.log(this.state.distance)
    return (
      <Fragment>
        <div
          className={"filter"}
          style={{
            // maxWidth:"calc(46px + 60vw)",
            width: "70vw",
            backgroundColor: "white",
            paddingLeft: "5vw",
            paddingRight: "5vw",
            borderRadius: 16,
            overflowY: "scroll",
            overflowX: "hidden",
            border: !this.props.heightFilter ? "1px solid #ddd" : 0,
            maxHeight: this.props.heightFilter ? "0vh" : "70vh",
            marginTop: 8,
          }}
        >
          <div
            style={{
              fontSize: bigFontSize,
              fontWeight: "bold",
              paddingTop: 20,
            }}
          >
            Filtri popolari
          </div>

          <hr
            style={{
              width: "60vw",
              border: " 1px solid gainsboro",
              borderRadius: "2px",
              marginTop: 20,
              marginBottom: 20,
            }}
          />

          <div>Data</div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {data.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: 16,
                }}
              >
                <input
                  id={index + "0"}
                  type="radio"
                  value={item.name}
                  name={item.name}
                  // onClick={() => this.onclickRadioButtonDate(item.name)}
                  onClick={() => this.handleClickRadioButton("date", item.name)}
                  checked={this.state.date === item.name ? true : false}
                />
                <label
                  for={index + "0"}
                  style={{
                    fontSize: smallFontSize,
                    paddingLeft: paddingLeftRadioButton,
                  }}
                >
                  {item.name}
                </label>
              </div>
            ))}
          </div>

          <div style={{ padding: "20px 0", fontSize: mediumFontSize }}>
            Lunghezza
          </div>
          <form
            action=""
            style={{
              padding: "16px 0",
              paddingRight: formPadding,
              paddingLeft: formPadding,
            }}
          >
            <InputRange
              maxValue={500}
              minValue={0}
              formatLabel={(value) => `${value} Km`}
              value={this.state.distance}
              onChange={(value) => this.handleRange("distance", value)}
            />
          </form>

          <div style={{ padding: "20px 0", fontSize: mediumFontSize }}>
            Dislivello
          </div>
          <form
            action=""
            style={{
              padding: "16px 0",
              paddingRight: formPadding,
              paddingLeft: formPadding,
            }}
          >
            <InputRange
              maxValue={10000}
              minValue={0}
              formatLabel={(value) => `${value} m`}
              value={this.state.elevation}
              onChange={(value) => this.handleRange("elevation", value)}
            />
          </form>

          <div style={{ paddingTop: 20 }}>Quale paesaggio vuoi vedere?</div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {landscape.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: 16,
                }}
              >
                <input
                  id={index + "1"}
                  type="radio"
                  value={item.name}
                  name={item.name}
                  onClick={() => this.onclickRadioButtonLandscape(item.name)}
                  checked={
                    this.state.landscapeFilter === item.name ? true : false
                  }
                />
                <label
                  for={index + "1"}
                  style={{
                    fontSize: smallFontSize,
                    paddingLeft: paddingLeftRadioButton,
                  }}
                >
                  {item.name}
                </label>
              </div>
            ))}
          </div>

          <div style={{ paddingTop: 20 }}>Stile</div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {style.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: 16,
                }}
              >
                <input
                  id={index + "2"}
                  type="radio"
                  value={item.name}
                  name={item.name}
                  onClick={() => this.onclickRadioButtonStyle(item.name)}
                  checked={this.state.styleFilter === item.name ? true : false}
                />
                <label
                  for={index + "2"}
                  style={{
                    fontSize: smallFontSize,
                    paddingLeft: paddingLeftRadioButton,
                  }}
                >
                  {item.name}
                </label>
              </div>
            ))}
          </div>

          <div style={{ paddingTop: 20 }}>Giudizio</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: 32,
            }}
          >
            {feedback.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: 16,
                }}
              >
                <input
                  id={index + "3"}
                  type="radio"
                  value={item.name}
                  name={item.name}
                  // onClick={() => this.onclickRadioButtonFeedback(item.name)}
                  onClick={() =>
                    this.handleClickRadioButton("rating", item.name)
                  }
                  checked={this.state.rating === item.name ? true : false}
                />
                <label
                  for={index + "3"}
                  style={{
                    fontSize: smallFontSize,
                    paddingLeft: paddingLeftRadioButton,
                  }}
                >
                  {item.name}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div
          className={"filter"}
          style={{
            // minWidth:"calc(46px + 60vw)",
            width: "70vw",
            backgroundColor: "white",
            paddingLeft: "5vw",
            paddingRight: "5vw",
            // border:this.props.heightSort ? "none" : "1px solid lightGrey",
            borderRadius: 16,
            overflowY: "scroll",
            border: !this.props.heightSort ? "1px solid #ddd" : 0,
            maxHeight: this.props.heightSort ? "0vh" : "70vh",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            {orderType.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "3vh",
                  paddingBottom: "0vh",
                }}
              >
                <input
                  id={index + "4"}
                  type="radio"
                  value={item.value}
                  name={item.name}
                  // onClick={() => this.onclickRadioButtonOrder(item.name)}
                  onClick={() =>
                    this.handleClickRadioButton("sort", item.value)
                  }
                  checked={this.state.sort === item.value ? true : false}
                />
                <label
                  for={index + "4"}
                  style={{
                    fontSize: smallFontSize,
                    paddingLeft: paddingLeftRadioButton,
                  }}
                >
                  {item.name}
                </label>
              </div>
            ))}
            <div style={{ paddingBottom: "3vh" }}></div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default FilterOrderField;
