import React, { Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

class BackButton extends React.Component {
  goToRace = (page) => {
    this.props.history.push("/" + page);
  };

  render() {
    return (
      <Fragment>
        <div
          style={{
            width: "46px",
            height: "46px",
            borderRadius: "50%",
            border: "0px solid black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            marginRight: "1.2vw",
          }}
          className={"circleTransition circleShadow"}
          onClick={() => this.goToRace(this.props.previousPage)}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ height: "28px", width: "28px" }}
            className={"iconSingleResult"}
          />
        </div>
      </Fragment>
    );
  }
}

export default BackButton;
