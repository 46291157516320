import React, { Fragment } from "react";
import Header from "../../component/Header";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import "../../style/LoginStyle.css";
import {
  TransitionGroup,
  CSSTransition,
  Transition,
} from "react-transition-group";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const smallFontSize = "12px";
const mediumFontSize = "16px";
const bigFontSize = "18px";
const veryBigFontSize = "24px";

class LoginPage extends React.Component {
  state = {
    actualPage: "login",
    rememberMe: false,
    dateBirth: new Date(),
    mailingList: false,
  };

  goToPage = (page) => {
    this.props.history.push("/" + page);
  };

  render() {
    return (
      <Fragment>
        <Header {...this.props} />
        <div style={{ paddingTop: "calc(max(5vh, 50px))", paddingLeft: "0" }}>
          <div
            style={{ display: "flex", paddingLeft: "7vw", paddingTop: "3vh" }}
          >
            <div
              style={{ fontSize: bigFontSize, cursor: "pointer" }}
              className={this.state.actualPage === "login" ? "highlighted" : ""}
              onClick={() => this.setState({ actualPage: "login" })}
            >
              Accedi
            </div>
            <div
              style={{
                fontSize: bigFontSize,
                paddingLeft: "9vw",
                cursor: "pointer",
              }}
              className={
                this.state.actualPage === "register" ? "highlighted" : ""
              }
              onClick={() => this.setState({ actualPage: "register" })}
            >
              Registrati
            </div>
          </div>
          <hr
            style={{
              width: "86vw",
              border: " 1px solid gainsboro",
              borderRadius: "2px",
              marginTop: "2.5vh",
              marginBottom: "2.5vh",
            }}
          />
          {this.state.actualPage === "login" ? (
            <div>
              <div style={{ paddingLeft: "7vw" }}>
                <div style={{ paddingBottom: "2.0vh", fontWeight: "bold" }}>
                  Username
                </div>
                <input
                  type={"text"}
                  style={{
                    width: "86vw",
                    border: "1px solid gainsboro",
                    height: "32px",
                    borderRadius: "5px",
                  }}
                  placeholder="Inserisci il tuo username..."
                />
                <div
                  style={{
                    paddingTop: "3.6vh",
                    paddingBottom: "2.0vh",
                    fontWeight: "bold",
                  }}
                >
                  Password
                </div>
                <input
                  type={"text"}
                  style={{
                    width: "86vw",
                    border: "1px solid gainsboro",
                    height: "32px",
                    borderRadius: "5px",
                  }}
                  placeholder="Inserisci la password..."
                />
                <div style={{ paddingTop: "1vh" }}>
                  <input
                    type="checkbox"
                    id={"rememberMe"}
                    onClick={() =>
                      this.setState({ rememberMe: !this.state.rememberMe })
                    }
                  />
                  <label for="rememberMe" style={{ fontSize: smallFontSize }}>
                    Ricordami
                  </label>
                </div>
                <div
                  style={{
                    width: "86vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "3vh",
                    flexDirection: "column",
                  }}
                >
                  <button
                    style={{
                      width: "36vw",
                      height: "8vh",
                      borderRadius: "4vh",
                      backgroundColor: "rgb(148,214,10, 0.8)",
                      border: "none",
                      color: "white",
                      fontSize: bigFontSize,
                    }}
                    onClick={() => this.goToPage("Home")}
                  >
                    LOGIN
                  </button>
                  <div
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      textAlign: "center",
                      paddingTop: "2.6vh",
                      fontSize: smallFontSize,
                    }}
                  >
                    Hai dimenticato la password?
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100vw",
                  paddingTop: "0.6vh",
                }}
              >
                <hr
                  style={{
                    width: "86vw",
                    border: " 1px solid gainsboro",
                    borderRadius: "2px",
                    marginTop: "2.5vh",
                    marginBottom: "2.5vh",
                  }}
                />

                <GoogleLoginButton
                  style={{ width: "86vw", height: "42px" }}
                  onClick={() => this.goToPage("Home")}
                />
                <FacebookLoginButton
                  style={{ width: "86vw", height: "42px" }}
                  onClick={() => this.goToPage("Home")}
                />
              </div>
            </div>
          ) : (
            <div style={{ display: "none" }}>Transparent</div>
          )}

          {this.state.actualPage === "register" ? (
            <Fragment>
              <div style={{ paddingLeft: "7vw" }}>
                <div style={{ paddingBottom: "2.0vh", fontWeight: "bold" }}>
                  Username
                </div>
                <input
                  type={"text"}
                  style={{
                    width: "86vw",
                    border: "1px solid gainsboro",
                    height: "32px",
                    borderRadius: "5px",
                  }}
                  placeholder="Inserisci il tuo username..."
                />
                <div
                  style={{
                    paddingTop: "2.6vh",
                    paddingBottom: "2.0vh",
                    fontWeight: "bold",
                  }}
                >
                  Email
                </div>
                <input
                  type={"text"}
                  style={{
                    width: "86vw",
                    border: "1px solid gainsboro",
                    height: "32px",
                    borderRadius: "5px",
                  }}
                  placeholder="Inserisci la tua mail..."
                />
                <div
                  style={{
                    paddingTop: "2.6vh",
                    paddingBottom: "2.0vh",
                    fontWeight: "bold",
                  }}
                >
                  Nome
                </div>
                <input
                  type={"text"}
                  style={{
                    width: "86vw",
                    border: "1px solid gainsboro",
                    height: "32px",
                    borderRadius: "5px",
                  }}
                  placeholder="Inserisci il tuo nome..."
                />
                <div
                  style={{
                    paddingTop: "2.6vh",
                    paddingBottom: "2.0vh",
                    fontWeight: "bold",
                  }}
                >
                  Cognome
                </div>
                <input
                  type={"text"}
                  style={{
                    width: "86vw",
                    border: "1px solid gainsboro",
                    height: "32px",
                    borderRadius: "5px",
                  }}
                  placeholder="Inserisci il tuo cognome..."
                />
                <div
                  style={{
                    paddingTop: "2.6vh",
                    paddingBottom: "2.0vh",
                    fontWeight: "bold",
                  }}
                >
                  Password
                </div>
                <input
                  type={"text"}
                  style={{
                    width: "86vw",
                    border: "1px solid gainsboro",
                    height: "32px",
                    borderRadius: "5px",
                  }}
                  placeholder="Inserisci la password..."
                />
                <div
                  style={{
                    paddingTop: "2.6vh",
                    paddingBottom: "2.0vh",
                    fontWeight: "bold",
                  }}
                >
                  Reinserisci la password
                </div>
                <input
                  type={"text"}
                  style={{
                    width: "86vw",
                    border: "1px solid gainsboro",
                    height: "32px",
                    borderRadius: "5px",
                  }}
                  placeholder="Inserisci la password..."
                />
                <div
                  style={{
                    paddingTop: "2.6vh",
                    paddingBottom: "2.0vh",
                    fontWeight: "bold",
                  }}
                >
                  Data di nascita
                </div>
                <DatePicker
                  selected={this.state.dateBirth}
                  onChange={(date) => this.setState({ dateBirth: date })}
                  className={"datePicker"}
                />
              </div>
              <div
                style={{
                  width: "100vw",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ paddingTop: "3vh", display: "flex", width: "86vw" }}
                >
                  <input
                    type="checkbox"
                    id={"rememberMe"}
                    onClick={() =>
                      this.setState({ mailingList: !this.state.mailingList })
                    }
                  />
                  <label
                    htmlFor="rememberMe"
                    style={{ fontSize: smallFontSize }}
                  >
                    Sì, voglio iscrivermi alla vostra mailing list!
                  </label>
                </div>
                <div
                  style={{
                    width: "82vw",
                    paddingTop: "2.5vh",
                    paddingBottom: "4.5vh",
                    fontSize: smallFontSize,
                  }}
                >
                  Ci impegnamo a protegger la tua privacy, pertanto il tuo
                  indirizzo email non verrà ceduto a terzi. Proseguendo confermi
                  di accettare le INFO LEGALI e i TERMINI E CONDIZIONI
                </div>
                <button
                  style={{
                    width: "42vw",
                    height: "8vh",
                    borderRadius: "4vh",
                    marginBottom: "4.5vh",
                    backgroundColor: "rgb(148,214,10, 0.8)",
                    border: "none",
                    color: "white",
                    fontSize: bigFontSize,
                  }}
                  onClick={() => this.goToPage("Home")}
                >
                  REGISTRATI
                </button>
              </div>
            </Fragment>
          ) : (
            <div style={{ display: "none" }}>Transparent</div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default LoginPage;
