import React, { Fragment } from "react";
import { Carousel } from "react-responsive-carousel";
import img1 from "../img/landing/men-1245982_1920.jpg";
import img2 from "../img/landing/trail1.jpg";

const images = [
  {
    number: 1,
    name: "img1",
    src: img1,
    raceName: "Orobie ultra Trail 0",
    legend: {
      price: 30,
      deadline: new Date("01/01/2021"),
      km: "150",
      distance: "36",
    },
  },
  {
    number: 2,
    name: "img2",
    src: img2,
    raceName: "Orobie ultra Trail 1",
    legend: {
      price: 50,
      deadline: new Date("01/02/2021"),
      km: "210",
      distance: "10",
    },
  },
  {
    number: 3,
    name: "img3",
    src: img1,
    raceName: "Orobie ultra Trail 2",
    legend: {
      price: 80,
      deadline: new Date("01/03/2021"),
      km: "300",
      distance: "200",
    },
  },
  {
    number: 4,
    name: "img4",
    src: img2,
    raceName: "Orobie ultra Trail 3",
    legend: {
      price: 30,
      deadline: new Date("01/01/2021"),
      km: "150",
      distance: "400",
    },
  },
  {
    number: 5,
    name: "img5",
    src: img1,
    raceName: "Orobie ultra Trail 4",
    legend: {
      price: 30,
      deadline: new Date("01/01/2021"),
      km: "150",
      distance: "300",
    },
  },
  {
    number: 6,
    name: "img6",
    src: img2,
    raceName: "Orobie ultra Trail 5",
    legend: {
      price: 30,
      deadline: new Date("01/01/2021"),
      km: "150",
      distance: "600",
    },
  },
];

class RacePresentationSlider extends React.Component {
  state = {
    actualIndex: 0,
    actualRaceName: images[0].raceName,
  };
  updateIndex = (index) => {
    this.setState({ actualIndex: index });
    var name = images[index].raceName;
    this.setState({ actualRaceName: name });
  };
  goToRace = () => {
    this.props.history.push("/Race");
  };

  render() {
    return (
      <div style={{ paddingBottom: "4vh" }}>
        <div className="sliderTitle">{this.props.titleText}</div>
        <Carousel
          onClickItem={this.goToRace}
          className={"carousel9"}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          // selectedItem={this.state.actualIndex}
          onChange={(index) => this.updateIndex(index)}
        >
          {images.map((item, index) => (
            <Fragment>
              <img
                src={item.src}
                alt=""
                style={{ height: "30vh", width: "86vw", objectFit: "cover" }}
              />
              {!!item.legend && (
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    color: "black",
                    width: "25%",
                    height: "100%",
                    lineHeight: "16px",
                    display: "flex",
                    padding: "8px 16px",
                  }}
                >
                  <div>
                    {() => this.setState({ actualRaceName: item.raceName })}
                  </div>
                  <div
                    style={{
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      width: "100%",
                    }}
                  >
                    <div>
                      <div style={{ fontSize: 23, lineHeight: "24px" }}>
                        {renderDate(item.legend.deadline.getDate())}
                      </div>
                      <div style={{ fontSize: 14, lineHeight: "16px" }}>
                        {renderMonth(item.legend.deadline.getMonth())}
                      </div>
                      <div style={{ fontSize: 14, lineHeight: "14px" }}>
                        {item.legend.deadline.getFullYear()}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginTop: 0,
                        marginBottom: 0,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {item.legend.km} km
                    </div>

                    {this.props.vicinoATe ? (
                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          marginBottom: 0,
                          fontSize: 14,
                          textAlign: "center",
                        }}
                      >{`${item.legend.distance} km da te`}</div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          marginTop: 0,
                          marginBottom: 0,
                          fontSize: 14,
                          textAlign: "center",
                        }}
                      >{`${item.legend.distance} D+`}</div>
                    )}
                  </div>
                </div>
              )}
            </Fragment>
          ))}
        </Carousel>
        <div
          style={{
            width: "86vw",
            color: "black",
            fontSize: "16px",
            fontWeight: "bold",
            display: "block",
            paddingTop: "1vh",
            paddingLeft: "7vw",
            textAlign: "center",
            marginTop: "-11vh",
          }}
        >
          {this.state.actualRaceName}
        </div>
      </div>
    );
  }
}

export default RacePresentationSlider;

const renderMonth = (month) => {
  switch (month) {
    case 0:
      return "GEN";
    case 0:
      return "FEB";
    case 0:
      return "MAR";
    case 0:
      return "APR";
    case 0:
      return "MAG";
    case 0:
      return "GIU";
    case 0:
      return "LUG";
    case 0:
      return "AGO";
    case 0:
      return "SET";
    case 0:
      return "OTT";
    case 0:
      return "NOV";
    case 0:
      return "DIC";
  }
};

const renderDate = (date) => {
  if (String(date).length === 1) {
    return `0${date}`;
  } else {
    return date;
  }
};
