import React, { Fragment } from "react";
import "../style/hamburgers.css";

class MinusButton extends React.Component {
  state = {
    menuOpen: false,
  };
  changeMenuState = () => {
    if (!this.state.menuOpen) {
      this.setState({ menuOpen: true });
    } else {
      this.setState({ menuOpen: false });
    }
  };
  render() {
    return (
      <Fragment>
        <button
          className={
            this.state.menuOpen
              ? "hamburger hamburger--minus is-active"
              : "hamburger hamburger--minus"
          }
          type="button"
          onClick={this.changeMenuState}
          style={{ outline: "none" }}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </Fragment>
    );
  }
}

export default MinusButton;
