import React, { Fragment } from "react";
import img1 from "../../img/landing/men-1245982_1920.jpg";
import img2 from "../../img/landing/trail1.jpg";
import { Carousel } from "react-responsive-carousel";
import "../../style/LandingStyle.css";
import calendario from "../../img/home/Calendar.svg";
import pennina from "../../img/home/Pennina.svg";
import posizione from "../../img/searchResult/iconfinder_icons_pin_1564524 (2).png";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRunning,
  faStar,
  faPlus,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import runvisorTick from "../../img/loghi/loghi/runvisorTick.png";
import Banner from "../../component/Banner";
import DotFeedback from "../../component/DotFeedback";
import RaceDetail from "../../component/RaceDetail";

const smallFontSize = "12px";
const mediumFontSize = "16px";
const bigFontSize = "18px";
const veryBigFontSize = "24px";

const images = [
  {
    name: "img1",
    src: img1,
    legend: {
      price: 30,
      deadline: new Date("01/01/2021"),
    },
  },
  {
    name: "img2",
    src: img2,
    legend: {
      price: 50,
      deadline: new Date("01/02/2021"),
    },
  },
  {
    name: "img3",
    src: img1,
    legend: {
      price: 80,
      deadline: new Date("01/03/2021"),
    },
  },
  {
    name: "img4",
    src: img2,
  },
  {
    name: "img5",
    src: img1,
  },
  {
    name: "img6",
    src: img2,
  },
];

class SingleRunResult extends React.Component {
  state = {
    run: false,
    pref: false,
  };

  goToRace = () => {
    this.props.history.push("/Race/" + this.props.race.id);
    window.scrollTo(0, 0);
  };

  goToReview = () => {
    this.props.history.push("/Recensione");
  };

  render() {
    const rating = Math.floor(Math.random() * 100);
    const { race } = this.props;

    return (
      <div
        style={{
          maxWidth: "100vw",
          paddingLeft: "0px",
          paddingRight: "0px",
          marginBottom: "0vh",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ borderRadius: "50%" }}>
          <div>
            <Carousel
              // onClickItem={this.goToRace}
              className={"carousel2"}
              showThumbs={false}
              showStatus={false}
              showArrows={false}
            >
              {images.map((item, index) => (
                <div onClick={this.goToRace}>
                  <img
                    onClick={this.goToRace}
                    src={item.src}
                    alt=""
                    style={{ height: "30vh", width: "86", objectFit: "cover" }}
                  />
                  {!!item.legend && (
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        backgroundColor: "rgba(0,0,0,0.5)",
                        color: "black",
                        width: "25%",
                        height: "100%",
                        lineHeight: "16px",
                        display: "flex",
                      }}
                    >
                      <div
                        onClick={this.goToRace}
                        style={{
                          color: "white",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#f5f5f5",
                            borderRadius: 100,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 56,
                            height: 56,
                            color: "black",
                            fontWeight: "bold",
                            fontSize: 18,
                            // padding: 24
                          }}
                        >
                          {`€${item.legend.price}`}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            marginBottom: 6,
                            fontSize: 13,
                            textAlign: "center",
                          }}
                        >
                          se prenoti entro
                        </div>
                        <div>
                          <div style={{ fontSize: 21, lineHeight: "24px" }}>
                            {renderDate(
                              new Date(
                                moment(race["Data"]).format("DD/MM/YYYY")
                              ).getDate()
                            )}
                          </div>
                          <div style={{ fontSize: 14, lineHeight: "16px" }}>
                            {renderMonth(
                              new Date(
                                moment(race["Data"]).format("DD/MM/YYYY")
                              ).getMonth()
                            )}
                          </div>
                          <div style={{ fontSize: 11, lineHeight: "14px" }}>
                            {new Date(race["Data"]).getFullYear()}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-end",
                      color: "white",
                      margin: 8,
                      padding: "8px 16px",
                      borderRadius: 8,
                      background: "rgba(0,0,0,0.5)",
                    }}
                  >
                    {`${index + 1}/${images.length}`}
                  </div>
                </div>
              ))}
              <div
                onClick={this.goToReview}
                style={{
                  background: "#222",
                  color: "white",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>Hai corso questa gara?</div>
                <div>Recensiscila!</div>
                <div
                  style={{
                    backgroundColor: "rgb(149, 214, 10)",
                    borderRadius: 50,
                    height: 50,
                    width: 50,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 12,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPen}
                    color="white"
                    style={{ fontSize: 18 }}
                  />
                </div>
              </div>
            </Carousel>
          </div>
          <div style={{ width: "86vw" }}>
            <RaceDetail race={race} />
            <hr
              style={{
                width: "84vw",
                border: " 1px solid gainsboro",
                borderRadius: "2px",
                marginTop: "4.5vh",
                marginBottom: "4.5vh",
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SingleRunResult;

const renderMonth = (month) => {
  console.log("month", month);
  switch (month) {
    case 0:
      return "GEN";
    case 1:
      return "FEB";
    case 2:
      return "MAR";
    case 3:
      return "APR";
    case 4:
      return "MAG";
    case 5:
      return "GIU";
    case 6:
      return "LUG";
    case 7:
      return "AGO";
    case 8:
      return "SET";
    case 9:
      return "OTT";
    case 10:
      return "NOV";
    case 11:
      return "DIC";
  }
};

const renderDate = (date) => {
  console.log("date", date);
  if (String(date).length === 1) {
    return `0${date}`;
  } else {
    return date;
  }
};
