import React, { Fragment } from "react";
import instagram from "../img/home/instagram-icon-8.svg";
import Collapse from "rc-collapse";
import { FacebookIcon, TwitterIcon } from "react-share";
var Panel = Collapse.Panel;

require("rc-collapse/assets/index.css");

class Footer extends React.Component {
  render() {
    return (
      <Fragment>
        <div style={{ paddingTop: "0vh", backgroundColor: "rgba(247,247,247" }}>
          <div
            style={{
              display: "flex",
              width: "100vw",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "2vh",
              paddingTop: "2.5vh",
              backgroundColor: "rgba(247,247,247",
            }}
          >
            <FacebookIcon
              size={32}
              round={true}
              style={{ cursor: "pointer" }}
            />
            <img
              className="instagram"
              src={instagram}
              height={33}
              style={{ cursor: "pointer" }}
            />
            <TwitterIcon size={32} round={true} style={{ cursor: "pointer" }} />
          </div>

          {/* <hr style={{display:"flex", justifyContent:"center", width:"96vw", border: "1px solid lightgrey", borderRadius: "20px", marginTop:"1.5vh", marginBottom:"1.5vh", backgroundColor: 'rgb(247, 247, 247)'}}/>
           */}
          <div
            style={{
              width: "94vw",
              margin: "0 3vw",
              height: 1,
              backgroundColor: "lightgrey",
            }}
          />

          <Collapse
            accordion={true}
            style={{ border: "none", outline: "none", paddingTop: "3vh" }}
          >
            <Panel
              header="Sei un Runner?"
              headerClass="my-header-class"
              style={{ border: "none", outline: "none" }}
            >
              <div style={{ marginBottom: 8, textDecoration: "underline" }}>
                Scrivi una recensione
              </div>
              <div style={{ marginBottom: 8, textDecoration: "underline" }}>
                Arricchisci la tua mappa
              </div>
              <div style={{ textDecoration: "underline" }}>
                Prenota un pettorale
              </div>
            </Panel>
            <Panel
              header="Sei un Organizzatore?"
              headerClass="my-header-class"
              style={{ border: "none", outline: "none" }}
            >
              <div style={{ marginBottom: 8, textDecoration: "underline" }}>
                Segnala la tua gara
              </div>
              <div style={{ textDecoration: "underline" }}>
                Rendi più visibile la tua gara
              </div>
            </Panel>
            <Panel
              header="Inserzioni pubblicitari"
              headerClass="my-header-class"
              style={{ border: "none", outline: "none" }}
            >
              <div style={{ marginBottom: 8, textDecoration: "underline" }}>
                Inserzioni pubblicitarie
              </div>
            </Panel>
            <Panel
              header="Privacy policy"
              headerClass="my-header-class"
              style={{ border: "none", outline: "none", userSelect: "none" }}
            >
              <div style={{ marginBottom: 8, textDecoration: "underline" }}>
                Privacy policy
              </div>
            </Panel>
          </Collapse>
          {/* <hr style={{display:"flex", justifyContent:"center", width:"96vw", border: "1px solid lightgrey", borderRadius: "20px", marginTop:"1.5vh", marginBottom:"2.5vh"}}/> */}
          <div
            style={{
              width: "94vw",
              margin: "3vh 3vw",
              height: 1,
              backgroundColor: "lightgrey",
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "100vw",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                width: "92vw",
                color: "grey",
                fontSize: "12px",
              }}
            >
              © 2014-2017 Runvisor srl. All rights reservered.
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                width: "92vw",
                textAlign: "center",
                color: "grey",
                fontSize: "12px",
              }}
            >
              Runvisor Srl is not a booking agent and does not change any
              service fees to the isers of the site. Runvisor srl is not
              responsible for content on external web sites
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                width: "92vw",
                color: "grey",
                fontSize: "12px",
                paddingBottom: "3vh",
              }}
            >
              Click here for More Info
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Footer;
