import React, { Fragment } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

export class MapContainer extends React.Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };

  shouldComponentUpdate(oldProps, oldState) {
    return false;
  }
  onMarkerClick = (place) => (props, marker, e) =>
    this.setState({
      selectedPlace: place,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  render() {
    const { defaultCenter, places, height, width } = this.props;

    return (
      <Map
        onClick={this.onMapClicked}
        initialCenter={defaultCenter}
        google={this.props.google}
        zoom={14}
        disableDefaultUI={true}
        containerStyle={{
          width: this.props.width,
          height: this.props.height,
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
        style={{
          width: this.props.width,
          height: this.props.height,
          zIndex: this.props.zIndex,
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Marker
          title={"The marker`s title will appear as a tooltip."}
          name={"Race"}
          position={{ lat: 45.694889, lng: 9.66996 }}
        />
        <Marker
          title={"The marker`s title will appear as a tooltip."}
          name={"Race1"}
          position={{ lat: 45.69489, lng: 9.669961 }}
        />
        <Marker
          title={"The marker`s title will appear as a tooltip."}
          name={"Race2"}
          position={{ lat: 45.694999, lng: 9.669965 }}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBQQANGO2pLqNazBVzV-KTwTXZ-omEe8wA",
})(MapContainer);
