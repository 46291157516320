import React, { Fragment } from "react";
import calendario from "../img/home/Calendar.svg";
import pennina from "../img/home/Pennina.svg";
import DotFeedback from "./DotFeedback";
import img1 from "../img/landing/newImage/Depositphotos_PAESAGGIO lago.jpg";
import img2 from "../img/landing/newImage/Depositphotos_PAESAGGIO montagna.jpg";
import RaceDetail from "./RaceDetail";

class SingleSlideFeedback extends React.Component {
  goToRace = (index) => {
    this.props.history.push("/Race/" + index);
  };

  render() {
    if (!!this.props.run) {
      const { item, i, length } = this.props;
      return (
        <div
          style={{
            margin: "6%",
            marginBottom: 0,
          }}
          // onClick={() => this.goToRace(item["id"])}
        >
          <div
            style={{
              position: "relative",
              borderRadius: 16,
              overflow: "hidden",
            }}
          >
            <img
              src={i % 2 === 0 ? img1 : img2}
              alt=""
              className="singleSlideImage"
              style={{ width: "100%", objectFit: "cover", minHeight: 200 }}
            />
          </div>
          <RaceDetail race={item} homeland/>
        </div>
      );
    }
    return (
      <Fragment>
        <div
          style={{
            height: "35vh",
            paddingTop: "0vh",
            flexDirection: "row",
            justifyContent: "center",
            paddingLeft: "10px",
            paddingRight: "10px",
            display: "flex",
            borderRadius: 16,
            overflow: "hidden",
          }}
        >
          <div style={{ backgroundColor: "#6e6a69", opacity: "0.45" }}>
            <img
              src={require("../img/landing/running2.jpg")}
              alt=""
              className="singleSlideImage"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                width: "85vw",
                height: "32vh",
                backgroundSize: "cover",
                objectFit: "cover",
                maxWidth: "450px",
                borderRadius: 16,
                overflow: "hidden",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              zIndex: "100",
              color: "white",
              justifyContent: "space-between",
              width: "85vw",
              maxWidth: "450px",
              height: "35vh",
              borderRadius: 16,
              overflow: "hidden",
            }}
          >
            <div
              style={{
                display: "flex",
                textAlign: "left",
                paddingLeft: "15px",
                paddingTop: "10px",
              }}
            >
              / 2020 /
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "15px",
                paddingTop: "2vh",
              }}
            >
              <div
                style={{ display: "flex", textAlign: "left", fontSize: "16px" }}
              >
                Samuele L.
              </div>
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                Orobie Ultra Trail
              </div>
            </div>
            <div
              style={{
                textAlign: "left",
                fontSize: "14px",
                paddingLeft: "15px",
                paddingRight: "15px",
                width: "76vw",
              }}
              className={"textShrink"}
            >
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui
              nunc mattis enim ut tellus elementum sagittis vitae et. Vel
              pharetra vel turpis nunc eget. Volutpat sed cras ornare arcu.
              Fames ac turpis egestas maecenas pharetra convallis posuere morbi
              leo. Erat velit scelerisque in dictum non consectetur. Cras
              fermentum odio eu feugiat pretium nibh. Sed pulvinar proin gravida
              hendrerit lectus. Imperdiet proin fermentum leo vel orci porta
              non. Sit amet purus gravida quis. Condimentum mattis pellentesque
              id nibh tortor id aliquet lectus. Egestas sed tempus urna et
              pharetra pharetra massa massa ultricies."
            </div>
            <div
              className="iconAndFeedbackContainer"
              style={{
                backgroundColor: "white",
                height: "3vh",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "center",
                maxWidth: "450px",
              }}
            >
              <div
                className="feedbackContainer"
                style={{
                  zIndex: "100",
                  paddingLeft: "15px",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  height: "3vh",
                  width: "auto",
                  maxWidth: "450px",
                }}
              >
                <DotFeedback />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const renderMonth = (month) => {
  switch (month) {
    case 0:
      return "GEN";
    case 1:
      return "FEB";
    case 2:
      return "MAR";
    case 3:
      return "APR";
    case 4:
      return "MAG";
    case 5:
      return "GIU";
    case 6:
      return "LUG";
    case 7:
      return "AGO";
    case 8:
      return "SET";
    case 9:
      return "OTT";
    case 10:
      return "NOV";
    case 11:
      return "DIC";
  }
};

const renderDate = (date) => {
  if (String(date).length === 1) {
    return `0${date}`;
  } else {
    return date;
  }
};

export default SingleSlideFeedback;
