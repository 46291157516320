import React, { Fragment } from "react";
import {
  TransitionGroup,
  CSSTransition,
  Transition,
} from "react-transition-group";
import comuni from "../../db/comuni_italiani.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class LandingPosition extends React.Component {
  state = {
    previousPage: this.props.previousPage,
    nextPage: this.props.nextPage,
    animation: "fade",
    inProp: true,
    geolocation: "to_ask",
    showPopUp: false,
  };

  componentDidMount() {
    this.requestGeolocation();
  }

  requestGeolocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("position", position);
        this.setState({
          geolocation: "available",
        });
      },
      (error) => {
        console.log("error", error);
        this.setState({
          geolocation: "blocked",
        });
      }
    );
  };

  handleSelectCity = () => {
    this.setState({
      showPopUp: true,
    });
  };

  selectCity = (sigla) => {
    this.setState({
      city: sigla,
    });
    setTimeout(() => {
      this.props.history.push("/" + this.props.nextPage);
    }, 500);
  };

  nextIndex = () => {
    this.setState({ animation: "fade" });
    setTimeout(() => {
      this.props.history.push("/" + this.props.nextPage);
    }, 50);
  };

  prevIndex = () => {
    this.setState({ animation: "fade" });
    setTimeout(() => {
      this.props.history.push("/" + this.props.previousPage);
    }, 50);
  };

  render() {
    return (
      <Fragment>
        <div style={{ maxHeight: window.innerHeight, overflow: "hidden" }}>
        {this.state.showPopUp && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
              height: window.innerHeight,
              width: "100vw",
              background: "rgba(0,0,0,0.4)",
            }}
          >
            <div
              style={{
                background: "#f5f5f5",
                padding: 16,
                borderRadius: 16,
                minWidth: "60%",
                maxHeight: "60vh",
                overflow: "scroll",
              }}
            >
              {comuni
                .filter(
                  (comune) =>
                    !!comune[
                      "Flag Comune capoluogo di provincia/città metropolitana/libero consorzio"
                    ]
                )
                .sort((a, b) =>
                  a["Denominazione in italiano"] >
                  b["Denominazione in italiano"]
                    ? 1
                    : -1
                )
                .map((provincia) => {
                  return (
                    <div
                      // onClick={() => this.setState({city: provincia["Sigla automobilistica"]})}
                      onClick={() =>
                        this.selectCity(provincia["Denominazione in italiano"])
                      }
                      style={{
                        padding: "16px 8px",
                        borderBottom: "1px solid #ccc",
                        color:
                          this.state.city ===
                          provincia["Denominazione in italiano"]
                            ? "white"
                            : "unset",
                        backgroundColor:
                          this.state.city ===
                          provincia["Denominazione in italiano"]
                            ? "rgb(149, 214, 10)"
                            : "unset",
                      }}
                    >
                      {provincia["Denominazione in italiano"]}
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        <div className="landingButtonBackground"></div>
        <TransitionGroup>
          <CSSTransition
            classNames="fade"
            key={this.props.sectionName}
            timeout={{ enter: 500, exit: 500 }}
            in={this.state.inProp}
            unmountOnExit={true}
            appear
          >
            <Fragment>
              <div className="positionTextContainer">
                <div
                  onClick={this.requestGeolocation}
                  className="positionImage"
                ></div>
                <div className="positionTitle">Aggiungi la tua posizione</div>
                <div className="positionSubtitle">
                  In questo modo potrai individuare le gare a te più vicine, o
                  avere evidenza della distanza effettiva di ogni evento
                  rispetto a dove ti trovi.
                  <br />
                  {/*Risparmierai tempo nella selezione dei filtri e potrai*/}
                  {/*ricevere notifiche dagli eventi in previsione solamente in*/}
                  {/*riferimento alla zona di tuo interesse. <br />*/}
                  Potrai sempre comunque modificare l'opzione in impostazioni
                  rendendo anonima la tua posizione.
                </div>
              </div>
              <div className="bottomMenuContainer">
                <div className="backSkipContainer">
                  {this.props.distance ? (
                    <div className="hiddenElement"></div>
                  ) : (
                    <div className="backButton" onClick={this.prevIndex}>
                      Indietro
                    </div>
                  )}
                  <div className="skipButton" onClick={this.nextIndex}>
                    Lo farò dopo
                  </div>
                </div>
                <button
                  className="nextButton"
                  onClick={() => {
                    if (this.state.geolocation === "to_ask") {
                      this.requestGeolocation();
                    } else if (this.state.geolocation === "available") {
                      this.nextIndex();
                    } else if (this.state.geolocation === "blocked") {
                      this.handleSelectCity();
                    }
                  }}
                >
                  {this.state.geolocation === "to_ask"
                    ? "ABILITA POSIZIONE"
                    : this.state.geolocation === "available"
                    ? "PROSEGUI"
                    : "SCEGLI UN COMUNE"}
                </button>
              </div>
            </Fragment>
          </CSSTransition>
        </TransitionGroup>
        </div>
      </Fragment>
    );
  }
}

export default LandingPosition;
