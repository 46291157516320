import React, { Fragment } from "react";

class DotFeedback extends React.Component {
  state = {
    rating:0,
  }

  render() {
    const { editable } = this.props
    return (
      <Fragment>
        <div
          style={{
            height: 16,
            width: 16,
            backgroundColor: this.state.rating <= 20 ?"lightGrey":"#95d60a",
            borderRadius: "50%",
            display: "flex",
            cursor: "pointer",
            marginRight: 2,
          }}
          onClick={() => editable && this.setState({rating: 20.1})}
        />
        <div
          style={{
            height: 16,
            width: 16,
            backgroundColor: this.state.rating <= 40 ?"lightGrey":"#95d60a",
            borderRadius: "50%",
            display: "flex",
            cursor: "pointer",
            marginRight: 2,
          }}
          onClick={() => editable && this.setState({rating: 40.1})}
        />
        <div
          style={{
            height: 16,
            width: 16,
            backgroundColor: this.state.rating <= 20*3 ?"lightGrey":"#95d60a",
            borderRadius: "50%",
            display: "flex",
            cursor: "pointer",
            marginRight: 2,
          }}
          onClick={() => editable && this.setState({rating: 60.1})}
        />
        <div
          style={{
            height: 16,
            width: 16,
            backgroundColor: this.state.rating <= 80 ?"lightGrey":"#95d60a",
            borderRadius: "50%",
            display: "flex",
            cursor: "pointer",
            marginRight: 2,
          }}
          onClick={() => editable && this.setState({rating: 80.1})}
        />
        <div
          style={{
            height: 16,
            width: 16,
            backgroundColor: this.state.rating <= 100 ?"lightGrey":"#95d60a",
            borderRadius: "50%",
            display: "flex",
            cursor: "pointer",
            marginRight: 2,
          }}
          onClick={() => editable && this.setState({rating: 100.1})}
        />
      </Fragment>
    );
  }
}

export default DotFeedback;
