import React, { Fragment, useState } from "react";
import {
  TransitionGroup,
  CSSTransition,
  Transition,
} from "react-transition-group";
import runvisorTick from "../../img/loghi/loghi/runvisorTick.png";

// tipo1
const distanza = [
  {
    id: "ultra",
    title: "ULTRA X - LONG",
    label: "> 100km",
    img: require("../../img/landing/newImage/Depositphotos_LUNGHEZZA Ultra X-Long.jpg"),
  },
  {
    id: "normal",
    title: "ULTRA LONG",
    label: "70 > 99 KM",
    img: require("../../img/landing/newImage/Depositphotos_LUNGHEZZA Ultra Long.jpg"),
  },
  {
    id: "slim",
    title: "ULTRA MEDIUM",
    label: "42 > 69 KM",
    img: require("../../img/landing/newImage/Depositphotos_LUNGHEZZA Ultra M.jpg"),
  },
  {
    id: "tutte",
    title: "SMALL",
    label: "> 42 KM",
    img: require("../../img/landing/newImage/Depositphotos_LUNGHEZZA Small.jpg"),
  },
];

// tipo2
const paesaggio = [
  {
    id: "montagna",
    title: "Montagna",
    label: "",
    img: require("../../img/landing/newImage/Depositphotos_PAESAGGIO montagna.jpg"),
  },
  {
    id: "mare",
    title: "Mare",
    label: "",
    img: require("../../img/landing/newImage/Depositphotos_PAESAGGIO mare.jpg"),
  
  },
  {
    id: "lago",
    title: "Lago",
    label: "",
    img: require("../../img/landing/newImage/Depositphotos_PAESAGGIO lago.jpg"),
  },
  {
    id: "sterrato",
    title: "Ovunque",
    label: "",
    img: require("../../img/landing/newImage/Depositphotos_PAESAGGIOovunque.jpg"),
  },
];

// elevation
const dislivello = [
  // {
  //   id: "elevato",
  //   title: "Elevato",
  //   label: "> 2000D+           ",
  //   img: require("../../img/landing/dislivello_1.jpg"),
  // },
  // {
  //   id: "moderato",
  //   title: "Moderato",
  //   label: "< 2000D+",
  //   img: require("../../img/landing/dislivello_2.jpg"),
  // },
  // {
  //   id: "collinare",
  //   title: "Collinare",
  //   label: "< 1000D+",
  //   img: require("../../img/landing/dislivello_3.jpg"),
  // },
  // {
  //   id: "assente",
  //   title: "Assente",
  //   label: "< 500D+",
  //   img: require("../../img/landing/dislivello_4.jpg"),
  // },
  {
    id: "elevato",
    title: "ELEVATO - ",
    label: "maggiore di 2500 D+",
    img: require("../../img/landing/newImage/Depositphotos_DISLIVELLO elevat.jpg"),
  },
  {
    id: "assente",
    title: "MODERATO - ",
    label: "minore di 2500 D+",
    img: require("../../img/landing/newImage/Depositphotos_DISLIVELLO moderato.jpg"),
  },
];

const vicinanza = [
  {
    id: "attaccato",
    title: "Vicino",
    label: "< 20km",
    text: '20 KM',
    // img: "/static/media/20km2nero (1).f536e2e8.jpg",
  },
  {
    id: "moderato",
    title: "Medio",
    label: "< 50km",
    text: '50 KM',
    // img: "/static/media/50km2nero.3c0ecb98.jpg",
  },
  {
    id: "lontano",
    title: "Lontano",
    label: "< 100km",
    text: '100 KM',
    // img: "/static/media/100km2nero.199e28ce.jpg",
  },
  {
    id: "ovunque",
    title: "Ovunque",
    label: "Senza limiti",
    text: 'OVUNQUE',
    // img: "/static/media/ovunquekm2nero.1a162f55.jpg",
  },
];

const data = [
  {
    id: "distance",
    title: "LUNGHEZZA",
    subtitle: "Su quale distanza vuoi sfidarti",
    options: distanza,
  },
  {
    id: "landscape",
    title: "PAESAGGIO",
    subtitle: "Dove vuoi correre?",
    options: paesaggio,
  },
  {
    id: "difference",
    title: "DISLIVELLO",
    subtitle: "Quanto in alto vuoi arrivare?",
    options: dislivello,
  },
  {
    id: "nearOrFar",
    title: "Vicino o lontano",
    subtitle: "Quanto lontano dalla tua posizione vuoi cercare le tue gare",
    options: vicinanza,
  },
];

class LandingButton extends React.Component {
  state = {
    previousPage: this.props.previousPage,
    nextPage: this.props.nextPage,
    inProp: true,
    tickAppear: "hiddenElement",
    distance: this.props.distance1,
    landscape: this.props.landscape1,
    difference: this.props.difference1,
    far: this.props.far1,
  };

  nextIndex = () => {
    const { nextPage } = this.props;
    setTimeout(() => {
      this.setState({ tickAppear: "hiddenElement" });
      this.props.history.push({
        pathname: '/'+ this.props.nextPage,
        state: {
          
          difference:this.props.sectionName=="diiference"?this.props.history.location.state.difference:this.state.difference,
          distance:this.props.sectionName=="diiference"?this.props.history.location.state.distance:this.state.distance,
          far:this.props.sectionName=="diiference"?this.props.history.location.state.far:this.state.far,
          landscape:this.props.sectionName=="diiference"?this.props.history.location.state.landscape:this.state.landscape,
        }})
    }, 500);
  };

  goHome = () => {
    const { nextPage } = this.props;
    setTimeout(() => {
      this.setState({ tickAppear: "hiddenElement" });
      this.props.history.push("/Home");
    }, 500);
  };

  prevIndex = () => {
    const { previousPage } = this.props;
    setTimeout(() => {
      this.setState({ tickAppear: "hiddenElement" });
      // this.props.history.push("/"+this.props.previousPage);
      this.props.history.goBack();
    }, 500);
  };

  render() {
    return (
      <Fragment>
        <div
          className="landingButtonBackground"
          style={{ maxHeight: window.innerHeight, overflow: "hidden" }}
        >
          <TransitionGroup>
            <CSSTransition
              classNames="fade"
              key={this.props.sectionName}
              timeout={{ enter: 500, exit: 500 }}
              in={this.state.inProp}
              unmountOnExit={true}
              appear
            >
              <Options
                data={data.find((obj) => obj.id === this.props.options)}
                nextIndex={this.nextIndex}
                dataName ={data.id}
              />
            </CSSTransition>
          </TransitionGroup>
          <div className="bottomMenuContainer">
            <div className="backSkipContainer">
              {this.props.distance ? (
                <div className="hiddenElement"></div>
              ) : (
                <div className="backButton" style={{fontSize: '14pt'}} onClick={this.prevIndex}>
                  Indietro
                </div>
              )}
              <div className="skipButton" style={{fontSize: '12pt'}} onClick={this.nextIndex}>Salta</div>
            </div>
            {/*<button className="nextButton" onClick={this.nextIndex}>Avanti</button>*/}
          </div>
        </div>
      </Fragment>
    );
  }
}

const Options = (props) => {
  const { data } = props;
  return (
    <div
      className=""
      style={{
        maxHeight: window.innerHeight,
        display: "flex",
        flexDirection: "column",
        padding: "0% 2%",
        position: "absolute",
      }}
    >
      <div
        className=""
        style={{ color: "black", height: "20%", textAlign: "center" }}
      >
        <div className="" style={{ fontSize: '30pt' }}>
          {data.title}
        </div>
        <div className="" style={{ fontSize: '14pt', color:"#666" }}>
          {data.subtitle}
        </div>
      </div>
      <div style={{fontSize:'12pt', textAlign:"center", paddingTop:6, color:"#666"}}>Potrai sempre modificare le tue preferenze più avanti</div>
      <div
        style={{
          display: "flex",
          flexDirection: data.id=="difference"?"column":"row",
          flexWrap: "wrap",
          height: "70%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 12,
        }}
        onClick={props.nextIndex}
      >
        {data.options.map((option, i) => (
          <Option
            option={option}
            i={i}
            imageFit={data.id === "nearOrFar" ? "contain" : "cover"}
            dataID = {data.id}
            dataName={props.dataName}
          />
        ))}
      </div>
    </div>
  );
};

const Option = (props) => {
  const [selected, setSelected] = useState(false);
  const { option, i, imageFit, dataID } = props;
  const imageHeight =
    (window.innerHeight - (window.innerHeight * 40) / 100 - 60) / 2 -
    (imageFit === "contain" ? 32 : 0);
  const setLocalStorage = event => {
    setSelected(!selected)
    if (option.id=="assente")
      {
        localStorage.setItem("elevation_min", 0);
        localStorage.setItem("elevation_max", 2500);
      } else
      if (option.id=="elevato")
      {
        localStorage.setItem("elevation_min", 2501);
        localStorage.setItem("elevation_max", 20000);
      }
      
      localStorage.setItem(props.dataID, option.id);
  }
  return (
    <div
      key={option.id}
      className=""
      style={{ width: dataID=="difference"?"100%":"50%", marginBottom: 8, position: "relative" }}
      onClick={setLocalStorage}
    >
      {option.img && <img
        src={option.img}
        style={{
          width: "97.0%",
          height: imageHeight,
          objectFit: imageFit,
          marginRight: i % 2 === 0 ? 4 : 0,
          marginLeft: i % 2 === 0 ? 0 : 4,
          borderRadius: 0,
          backgroundColor: "black",
          border:"1.5pt solid #888"
        }}
      />}
      {option.text && <div
        style={{
          width: "97.0%",
          height: imageHeight,
          marginRight: i % 2 === 0 ? 4 : 0,
          marginLeft: i % 2 === 0 ? 0 : 4,
          borderRadius: 0,
          backgroundColor: "black",
          border:"1.5pt solid #888",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: option.text.length > 6 ? '1.7em' : '2.1em',
          fontWeight: 'bold',
          color: shadeColor('#95d60a', i * -13)
        }}
      >
        {option.text}
      </div>}
      <div
        className=""
        style={{ color: "black", textAlign: "center" }}
      >
        <div style={{ fontSize: '14pt', fontWeight:"bold", marginTop: 8 }}>{option.title}</div>
        <div style={{ fontSize: '14pt', color: '#666', marginTop: 0 }}>{option.label}</div>
      </div>
      {selected && (
        <img
          src={runvisorTick}
          style={{
            position: "absolute",
            top: "16%",
            left: "14%",
            width: "72%",
            height: "48%",
          }}
        />
      )}
    </div>
  );
};

export default LandingButton;

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
  return componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function LightenDarkenColor(col, amt) {
  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  var b = ((num >> 8) & 0x00FF) + amt;
  var g = (num & 0x0000FF) + amt;
  var newColor = g | (b << 8) | (r << 16);
  return newColor.toString(16);
}

function shadeColor(color, percent) {

  var R = parseInt(color.substring(1,3),16);
  var G = parseInt(color.substring(3,5),16);
  var B = parseInt(color.substring(5,7),16);

  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);

  R = (R<255)?R:255;  
  G = (G<255)?G:255;  
  B = (B<255)?B:255;  

  var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
  var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
  var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

  return "#"+RR+GG+BB;
}