import React, { Fragment } from "react";
import { slide as Menu } from "react-burger-menu";

const menuItems = [
  {
    name: "Home",
    target: "",
  },
  {
    name: "Scrivi Recensione",
    target: "",
  },
  {
    name: "Log In / Sign UP",
    target: "",
  },
  {
    name: "Search",
    target: "",
  },
  {
    name: "Reviews",
    target: "",
  },
  {
    name: "News",
    target: "",
  },
  {
    name: "About",
    target: "",
  },
  {
    name: "Contact",
    target: "",
  },
  {
    name: "Help",
    target: "",
  },
];

class BurgerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      hover: false,
      burgerWidth: "33.4%",
      width: window.innerWidth,
      transitionTime: "800ms ease-in-out",
    };
    window.addEventListener("resize", this.setBurgerWidth);
  }
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  goToPage = (page) => {
    this.closeMenu();
    this.props.history.push("/" + page);
  };

  handleHover = (target) => {
    this.setState({
      hover: target,
    });
  };

  setBurgerWidth = () => {
    if (this.props.width <= 576) {
      this.setState({
        burgerWidth: "100%",
        transitionTime: "400ms ease-in-out",
      });
    } else {
      this.setState({ burgerWidth: "33.4%" });
    }
  };
  componentDidMount() {
    this.setBurgerWidth();
  }

  render() {
    const { hover } = this.state;
    return (
      <Fragment>
        {() => this.setBurgerWidth()}
        <Menu
          colorBurger={this.props.colorBurger}
          styles={{
            bmBurgerButton: {
              position: "fixed", //or fixed
              width: "30px",
              height: "30px",
              right: "4vw",
              color: "black",
              zoom: 0.6,
              zIndex: 1050,
              top: "2.8vh",
            },
            bmBurgerBars: {
              background: this.props.colorBurger,
              color: "black",
            },
            bmBurgerBarsHover: {
              background: "#a90000",
            },
            bmCrossButton: {
              position: "fixed",
              height: "30px",
              width: "30px",
              right: "2.85vw",
              top: "1.5vh",
            },
            bmCross: {
              // background: '#e6ce3c',
              background: "black",
              zoom: 1.2,
            },
            bmMenuWrap: {
              position: "fixed",
              height: "100vh",
              top: 0,
              transition: this.state.transitionTime,
              webkitBoxShadow: this.state.menuOpen
                ? "-14px 0px 35px -8px rgba(0,0,0,0.4)"
                : "",
              moxBoxShadow: this.state.menuOpen
                ? "-14px 0px 35px -8px rgba(0,0,0,0.4)"
                : "",
              boxShadow: this.state.menuOpen
                ? "-14px 0px 35px -8px rgba(0,0,0,0.4)"
                : "",
            },
            bmMenu: {
              background: "white",
              // background: '#91855d',
              padding: "0em 0.0em 0",
              fontSize: "32px",
              font: "Montserrat",
              overflow: "hidden",
              lineHeight: "43px",
            },
            bmMorphShape: {
              fill: "#373a47",
            },
            bmItemList: {
              color: "#000000",
              padding: "0.8em",
            },
            bmItem: {
              display: "flex",
              margin: "0% 0 10.5% 0", //distanza item del menu
              textdecoration: "none",
              cursor: "pointer",
              transition: "opacity 400ms ease-in-out",
              webkitTransition: "opacity 400ms ease-in-out",
              mozTransition: "opacity 400ms ease-in-out",
              outline: "none",
            },
            bmOverlay: {
              background: "rgba(0, 0, 0, 0.0)",
            },
          }}
          onStateChange={(state) => this.handleStateChange(state)}
          right
          disableAutoFocus
          width="100vw"
          isOpen={this.state.menuOpen}
        >
          {menuItems.map((item, index) => (
            <a
              onMouseEnter={() => this.handleHover(item.name)}
              onMouseLeave={() => this.handleHover(false)}
              id={item.name + index}
              className="menu-item"
              style={{ opacity: hover && hover !== item.name ? 0.6 : 1 }}
              onClick={() => this.goToPage(item.target)}
            >
              {item.name}
            </a>
          ))}
        </Menu>
      </Fragment>
    );
  }
}
export default BurgerMenu;
