import React, { Fragment } from "react";

class SlideTracker extends React.Component {
  goToPage = (page) => {
    this.props.history.push("/" + page);
  };

  render() {
    return (
      <Fragment>
        {this.props.landing1 ? (
          <Fragment>
            <span
              className="coloredDot"
              onClick={(page) => this.goToPage("")}
            ></span>
          </Fragment>
        ) : (
          <span className="dot" onClick={(page) => this.goToPage("")}></span>
        )}
        {this.props.landing2 ? (
          <Fragment>
            <span
              className="coloredDot"
              onClick={(page) => this.goToPage("Landing2")}
            ></span>
          </Fragment>
        ) : (
          <span
            className="dot"
            onClick={(page) => this.goToPage("Landing2")}
          ></span>
        )}
        {this.props.landing3 ? (
          <Fragment>
            <span
              className="coloredDot"
              onClick={(page) => this.goToPage("Landing3")}
            ></span>
          </Fragment>
        ) : (
          <span
            className="dot"
            onClick={(page) => this.goToPage("Landing3")}
          ></span>
        )}
      </Fragment>
    );
  }
}

export default SlideTracker;
