import React, { Component, Fragment } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { FullPage, Slide } from "react-full-snap";
import Slider from "react-slick";
import runvisor from "../../img/loghi/loghi/logo_3c.png";
import landing1 from "../../img/landing/RUNNING PEOPLE-38.png";
import landing2 from "../../img/landing/RUNNING PEOPLE-02.png";
import landing3 from "../../img/landing/RUNNING PEOPLE-04.png";
import runvisorLanding from "../../img/loghi/loghi/logo_3a.jpg";
import Landing123 from "./Landing123";
import backgroundLanding from "../../img/landing/Depositphotos_PAESAGGIO mare.jpg"

const landingData = [
  {
    text1: "CERCA LA TUA GARA,<br/> CORRI LA TUA GARA",
    text2: "Ogni runner si merita le gare più belle",
    text3: "",
    img: "landing1",
  },
  {
    text1: "PIANIFICA LE TUE CORSE,<br/> INVITA GLI AMICI",
    text2: "Aggiorna il tuo calendario e condividilo con gli amici",
    style: "",
    img: "landing2",
  },
  {
    text1: "PIANIFICA LE TUE CORSE,<br/> INVITA GLI AMICI",
    text2: "Aggiorna il tuo calendario e condividilo con gli amici",
    text3: "",
    style: "",
    img: "landing3",
  },
];

class Landing extends Component {
  nextIndex = () => {
    this.props.history.push("/Distance");
  };
  render() {
    return (
      <Fragment>
        {/*<Carousel showThumbs={false} showStatus={false} showArrows={false}>*/}
        {/*  <div>*/}
        {/*    <img className="landing1" style={{ height: window.innerHeight }} />*/}
        {/*    <div*/}
        {/*      className="landingTextContainer"*/}
        {/*      style={{ height: window.innerHeight }}*/}
        {/*    >*/}
        {/*      <div className="landing1Text">*/}
        {/*        <div*/}
        {/*          style={{*/}
        {/*            fontWeight: "bold",*/}
        {/*            fontSize: "60px",*/}
        {/*            lineHeight: "60px",*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          CERCA*/}
        {/*        </div>*/}
        {/*        <br />*/}
        {/*        <div style={{ fontSize: "30px", lineHeight: "30px" }}>*/}
        {/*          LA TUA GARA*/}
        {/*        </div>*/}
        {/*        <br />*/}
        {/*        <div*/}
        {/*          style={{*/}
        {/*            fontWeight: "bold",*/}
        {/*            fontSize: "60px",*/}
        {/*            lineHeight: "60px",*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          CORRI{" "}*/}
        {/*        </div>*/}
        {/*        <br />*/}
        {/*        <div style={{ fontSize: "30px", lineHeight: "30px" }}>*/}
        {/*          LA TUA GARA*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    <img className="landing2" style={{ height: window.innerHeight }} />*/}
        {/*    <div*/}
        {/*      className="landing2TextContainer"*/}
        {/*      style={{ height: window.innerHeight }}*/}
        {/*    >*/}
        {/*      <div className="landing1Text">*/}
        {/*        <div*/}
        {/*          style={{*/}
        {/*            fontWeight: "bold",*/}
        {/*            fontSize: "50px",*/}
        {/*            lineHeight: "40px",*/}
        {/*            textAlign: "right",*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          PIANIFICA*/}
        {/*        </div>*/}
        {/*        <br />*/}
        {/*        <div*/}
        {/*          style={{*/}
        {/*            fontSize: "20px",*/}
        {/*            lineHeight: "10px",*/}
        {/*            textAlign: "right",*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          LE TUE CORSE*/}
        {/*        </div>*/}
        {/*        <br />*/}
        {/*        <div*/}
        {/*          style={{*/}
        {/*            fontWeight: "bold",*/}
        {/*            fontSize: "50px",*/}
        {/*            lineHeight: "40px",*/}
        {/*            textAlign: "right",*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          INVITA*/}
        {/*        </div>*/}
        {/*        <br />*/}
        {/*        <div*/}
        {/*          style={{*/}
        {/*            fontSize: "20px",*/}
        {/*            lineHeight: "10px",*/}
        {/*            textAlign: "right",*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          I TUOI AMICI*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    <div className="landing3" style={{ height: window.innerHeight }}>*/}
        {/*      <div*/}
        {/*        style={{*/}
        {/*          height: "100%",*/}
        {/*          display: "flex",*/}
        {/*          flexDirection: "column",*/}
        {/*          justifyContent: "space-between",*/}
        {/*          padding: 32,*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <div>*/}
        {/*          <div className="landing3Text">*/}
        {/*            <div*/}
        {/*              style={{*/}
        {/*                fontWeight: "bold",*/}
        {/*                fontSize: "50px",*/}
        {/*                lineHeight: "40px",*/}
        {/*                textAlign: "left",*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              RECENSISCI*/}
        {/*            </div>*/}
        {/*            <br />*/}
        {/*            <div*/}
        {/*              style={{*/}
        {/*                fontSize: "20px",*/}
        {/*                lineHeight: "10px",*/}
        {/*                textAlign: "left",*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              LE TUE GARE*/}
        {/*            </div>*/}
        {/*            <br />*/}
        {/*            <div*/}
        {/*              style={{*/}
        {/*                fontWeight: "bold",*/}
        {/*                fontSize: "50px",*/}
        {/*                lineHeight: "40px",*/}
        {/*                textAlign: "left",*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              AIUTA*/}
        {/*            </div>*/}
        {/*            <br />*/}
        {/*            <div*/}
        {/*              style={{*/}
        {/*                fontSize: "20px",*/}
        {/*                lineHeight: "20px",*/}
        {/*                textAlign: "left",*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              GLI ALTRI RUNNER*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div*/}
        {/*          style={{*/}
        {/*            marginBottom: 32,*/}
        {/*            padding: 24,*/}
        {/*            borderRadius: 16,*/}
        {/*            background: "rgba(0,0,0,0.7)",*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <button*/}
        {/*            style={{*/}
        {/*              alignSelf: "center",*/}
        {/*              color: "black",*/}
        {/*              border: 0,*/}
        {/*              boxShadow: "0",*/}
        {/*              padding: "12px 24px",*/}
        {/*              backgroundColor: "white",*/}
        {/*              borderRadius: 16,*/}
        {/*              fontSize: 18,*/}
        {/*            }}*/}
        {/*            onClick={this.nextIndex}*/}
        {/*          >*/}
        {/*            Trova le tue gare*/}
        {/*          </button>*/}
        {/*          <div*/}
        {/*            style={{*/}
        {/*              color: "white",*/}
        {/*              paddingTop: "3vh",*/}
        {/*              alignSelf: "center",*/}
        {/*            }}*/}
        {/*          >*/}
        {/*            Rispondi a poche domande, troveremo le corse più adatte a te*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Carousel>*/}
        <div style={{ maxHeight: window.innerHeight, overflowY: "hidden" }}>
          
          <div style={{ position: "absolute", minHeight: 50, maxHeight: "5vh", width: "100%", backgroundColor: "white", display: "flex", alignItems: "center" }}>
            <img
              src={runvisorLanding}
              alt=""
              style={{
                objectFit: "fill",
                height: 40,
                marginLeft: 16,
                marginTop: -6 }} />
            <div style={{
              fontSize: 6,
              backgroundColor: 'white',
              marginLeft: -72,
              padding: 4,
              paddingTop: 12,
              paddingLeft: 8,
              paddingRight: 24,
              fontWeight: 'bold'}}>
              FROM RUNNERS<br/>
              TO RUNNERS
            </div>
          </div>
          
          <img
            className={"landing3"}
            alt=""
            style={{
              height: window.innerHeight,
              display: "flex",
              justifyContent: "center" }} />
          
          <div
            style={{ 
              position: "absolute", 
              right: "2vh", 
              top: "calc(max(50px, 5vh) + 10px", 
              fontSize: 21, 
              // fontFamily: "Bahnschrift cursive", 
              fontFamily: 'Bahnschrift', 
              fontStyle: "oblique", 
              fontWeight: "bold" 
            }}>«Ogni runner si merita<br /> la migliore esperienza»</div>
          
          <button
            style={{
              height: "5vh",
              minHeight: 50,
              backgroundColor: "gray",
              position: "absolute",
              bottom: 0,
              width: "100%",
              border: 0,
              fontSize: 24,
              fontFamily: 'Bahnschrift',
              fontWeight: "bold",
              color: "white"
            }}
            onClick={this.nextIndex}
          >
            TROVA LA TUA GARA
          </button>
        </div>
      </Fragment>
    );
  }
}

export default Landing;
