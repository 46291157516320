import React, { Fragment } from "react";
import Header from "../../component/Header";
import races from "../../mockupData/races";
import DotFeedback from "../../component/DotFeedback";
import img1 from "../../img/landing/RUNNING PEOPLE-38.png";
import ImageUpload from "image-upload-react";
//important for getting nice style.
import "image-upload-react/dist/index.css";
import RacePresentationSlider from "../../component/RacePresentationSlider";
import cross from "../../img/searchResult/iconfinder_Artboard_46_5208412.png";

const smallFontSize = "12px";
const mediumFontSize = "16px";
const bigFontSize = "18px";
const veryBigFontSize = "24px";

const feedbacktype = [
  { name: "Paesaggio" },
  { name: "Organizzazione" },
  { name: "Ristori" },
  { name: "Pacco gara" },
  { name: "Prezzo" },
];

class Recensione extends React.Component {
  state = {
    recensionePage: "1",
    passo: 12.7,
    imageSrc: "",
  };
  setField = (num, field) => {
    this.setState({[field.toLowerCase().replace(" ","")]: num}, () => console.log("ciao"))
  }

  componentDidUpdate(oldProps, oldState) {
    if (
      this.state.recensionePage !== oldState.recensionePage &&
      this.state.recensionePage === "4"
    ) {
      setTimeout(() => {
        this.goToPage(this.props.location.state.path);
      }, 3600);
    }
  }

  handleImageSelect = (e) => {
    this.setState({ imageSRC: URL.createObjectURL(e.target.files[0]) });
  };
  goToPage = (page) => {
    this.props.history.push("" + page);
  };

  render() {
    return (
      <Fragment>
        <Header {...this.props} />
        {this.state.recensionePage === "1" && (
          <Fragment>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "calc(max(5vh, 50px))",
              }}
            >
              <div
                style={{
                  fontSize: veryBigFontSize,
                  fontWeight: "bold",
                  paddingTop: "2.6vh",
                }}
              >
                Scrivi una recensione
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "86vw",
                }}
              >
                <input
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    paddingTop: "2.6vh",
                    border:"none", maxWidth:"86%"
                  }}
                  placeholder={this.props.location.state.name? this.props.location.state.name: "Inserisci la gara"}
                >
                </input>
                <img
                  src={cross}
                  alt=""
                  style={{
                    height: "24px",
                    width: "24px",
                    objectFit: "cover",
                    paddingTop: "2.6vh",
                    cursor: "pointer",
                  }}
                  onClick={() => this.goToPage(this.props.location.state.path)}
                />
              </div>
              <hr
                style={{
                  width: "86vw",
                  border: " 1px solid gainsboro",
                  borderRadius: "2px",
                  marginTop: "2.6vh",
                  marginBottom: "1.5vh",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "86vw",
                  paddingBottom: "2vh",
                  fontSize: mediumFontSize,
                  fontWeight: "bold",
                }}
              >
                <div>Il tuo tempo </div>
                <div>Il tuo passo</div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "86vw",
                }}
              >
                <form
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <input
                      type="number"
                      id="hours"
                      placeholder={"H"}
                      style={{
                        height: "40px",
                        width: "40px",
                        textAlign: "center",
                        borderRadius: "10px",
                        border: "1px solid gainsboro",
                      }}
                    />
                    <input
                      type="number"
                      id="minutes"
                      placeholder={"M"}
                      style={{
                        height: "40px",
                        width: "40px",
                        textAlign: "center",
                        marginLeft: "3vw",
                        marginRight: "3vw",
                        borderRadius: "10px",
                        border: "1px solid gainsboro",
                      }}
                    />
                    <input
                      type="number"
                      id="seconds"
                      placeholder={"S"}
                      style={{
                        height: "40px",
                        width: "40px",
                        textAlign: "center",
                        borderRadius: "10px",
                        border: "1px solid gainsboro",
                      }}
                    />
                  </div>
                </form>
                <div
                  style={{
                    height: "40px",
                    width: "80px",
                    textAlign: "center",
                    borderRadius: "10px",
                    border: "1px solid gainsboro",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {this.state.passo} km/h
                </div>
              </div>
              <div
                style={{
                  paddingTop: "1.2vh",
                  fontSize: smallFontSize,
                  width: "86vw",
                  textAlign: "right",
                }}
              >
                Oppurre carica la tua traccia
              </div>
              <hr
                style={{
                  width: "86vw",
                  border: " 1px solid gainsboro",
                  borderRadius: "2px",
                  marginTop: "2.6vh",
                  marginBottom: "1.5vh",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "0vh",
                  width: "86vw",
                }}
              >
                {feedbacktype.map((item, index) => (
                  <div
                    style={{
                      width: "80vw",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: mediumFontSize,
                      lineHeight: mediumFontSize,
                      paddingTop: "1.6vh",
                    }}
                  >
                    {item.name}
                    <div style={{ display: "flex" }}>
                      <DotFeedback editable/>
                    </div>
                  </div>
                ))}
              </div>
              <hr
                style={{
                  width: "86vw",
                  border: " 1px solid gainsboro",
                  borderRadius: "2px",
                  marginTop: "2.6vh",
                  marginBottom: "1.5vh",
                }}
              />
              <div
                style={{
                  fontSize: mediumFontSize,
                  fontWeight: "bold",
                  marginBottom: "1.5vh",
                }}
              >
                Carica le tue Immagini
              </div>
              <ImageUpload
                handleImageSelect={this.handleImageSelect}
                imageSrc={this.state.imageSrc}
                setImageSrc={this.setState}
                style={{
                  width: "86vw",
                  height: "16vh",
                  background: "gainsboro",
                  marginTop: "0",
                }}
              />
              {/*<button style={{width:"42vw", height:"6vh", borderRadius:"4vh",marginTop:"1.6vh", backgroundColor:"rgb(148,214,10, 0.8)", border:"none", color:"white", fontSize:bigFontSize}} onClick={() => this.setState({recensionePage:"2"})}>Avanti</button>*/}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "calc(max(5vh, 50px))",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingTop: "1.6vh",
                }}
              >
                <div style={{ paddingBottom: "2.0vh", fontWeight: "bold" }}>
                  Titolo
                </div>
                {/*<img src={cross} alt="" style={{height:"24px", width:"24px", objectFit:"cover", paddingLeft:"45vw", position:"absolute", cursor:"pointer"}} onClick={() => this.goToPage("Home")}/>*/}
              </div>
              <textarea
                type={"text"}
                style={{
                  width: "86vw",
                  border: "1px solid gainsboro",
                  height: "32px",
                  borderRadius: "5px",
                  resize: "none",
                }}
                placeholder="Scrivi il titolo della recensione..."
              />
              <div
                style={{
                  paddingBottom: "2.0vh",
                  fontWeight: "bold",
                  paddingTop: "1.6vh",
                }}
              >
                Recensione
              </div>
              <textarea
                type={"text"}
                style={{
                  width: "86vw",
                  border: "1px solid gainsboro",
                  // height: "32px",
                  borderRadius: "5px",
                  height: "20vh",
                  resize: "none",
                }}
                placeholder="Scrivi la recensione della tua gara..."
              />
              <div
                style={{
                  paddingBottom: "2.0vh",
                  fontWeight: "bold",
                  paddingTop: "1.6vh",
                }}
              >
                Consigli per gli altri runner
              </div>
              <textarea
                type={"text"}
                style={{
                  width: "86vw",
                  border: "1px solid gainsboro",
                  // height: "32px",
                  borderRadius: "5px",
                  height: "12vh",
                  resize: "none",
                }}
                placeholder="Scrivi ciò che consiglieresti agli altri runner per affrontare al meglio la gara..."
              />
              <div
                style={{
                  paddingBottom: "2.0vh",
                  fontWeight: "bold",
                  paddingTop: "1.6vh",
                }}
              >
                Consigli per gli organizzatori
              </div>
              <textarea
                type={"text"}
                style={{
                  width: "86vw",
                  border: "1px solid gainsboro",
                  // height: "32px",
                  borderRadius: "5px",
                  height: "12vh",
                  resize: "none",
                }}
                placeholder="Scrivi ciò che consiglieresti agli organizzatori per migliorare la gara..."
              />
              {/*<button style={{width:"42vw", height:"6vh", borderRadius:"4vh",marginTop:"1.6vh", backgroundColor:"rgb(148,214,10, 0.8)", border:"none", color:"white", fontSize:bigFontSize}} onClick={() => this.setState({recensionePage:"3"})}>Avanti</button>*/}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingTop: "5vh",
                paddingBottom: "6vh",
                width: "100vw",
              }}
            >
              {/*<img*/}
              {/*  src={cross}*/}
              {/*  alt=""*/}
              {/*  style={{*/}
              {/*    height: "24px",*/}
              {/*    width: "24px",*/}
              {/*    objectFit: "cover",*/}
              {/*    paddingLeft: "calc(86vw - 24px)",*/}
              {/*    top: "calc(max(5vh + 20px, 50px + 20px))",*/}
              {/*    position: "absolute",*/}
              {/*    cursor: "pointer",*/}
              {/*  }}*/}
              {/*  onClick={() => this.goToPage(this.props.location.state)}*/}
              {/*/>*/}

              <div
                style={{
                  fontSize: bigFontSize,
                  fontWeight: "bold",
                  textAlign: "left",
                  width: "86vw",
                  paddingBottom: "2.2vh",
                }}
              >
                Invia recensione
              </div>
              <div style={{ width: "86vw" }}>
                Dichiaro che questa recensione è frutto della mia esperienza e
                che rappresenta la mia opinione autentica di questa gara e di
                non avere relazioni personali o aziendali con gli organizzatori
                e che non mi sono mai stati offerti incentivi o pagamenti per
                scrivere questa recensione.
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "86vw",
                  paddingTop: "3vh",
                }}
              >
                <div
                  style={{
                    fontSize: mediumFontSize,
                    fontWeight: "bold",
                    color: "rgb(148,214,10, 1)",
                    paddingRight: "4vw",
                  }}
                  onClick={() => this.setState({ recensionePage: "4" })}
                >
                  ACCETTO
                </div>
                <div
                  style={{
                    fontSize: mediumFontSize,
                    fontWeight: "bold",
                    color: "rgb(148,214,10, 1)",
                  }}
                  onClick={() => this.goToPage(this.props.location.state.path)}
                >
                  RIFIUTO
                </div>
              </div>
            </div>
          </Fragment>
        )}
        {this.state.recensionePage === "4" && (
          <Fragment>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: window.innerHeight,
                width: "100vw",
              }}
            >
              <div style={{ fontSize: veryBigFontSize, fontWeight: "bold" }}>
                Complimenti!
              </div>
              <hr
                style={{
                  width: "86vw",
                  border: " 1px solid gainsboro",
                  borderRadius: "2px",
                  marginTop: "3.6vh",
                  marginBottom: "3.5vh",
                }}
              />

              <div>La tua recensione è stata inserita!</div>
              <hr
                style={{
                  width: "86vw",
                  border: " 1px solid gainsboro",
                  borderRadius: "2px",
                  marginTop: "3.6vh",
                  marginBottom: "0vh",
                }}
              />

              <img
                src={img1}
                alt=""
                style={{
                  width: "86vw",
                  borderRadius: "15px",
                  marginTop: "3.6vh",
                }}
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default Recensione;
