import React, { Fragment } from "react";
import SearchField from "./SearchField";
import FilterOrderField from "./FilterOrderField";

class SearchBox extends React.Component {
  state = {
    filterOpen: false,
    sortOpen: false,
  };

  componentDidUpdate(oldProps) {
    if (oldProps.showFilter !== this.props.showFilter) {
      this.setState({
        filterOpen: true,
        sortOpen: false,
      });
    }
    if (oldProps.showSort !== this.props.showSort) {
      this.setState({
        filterOpen: false,
        sortOpen: true,
      });
    }
  }

  openOnlyOne = (section) => {
    if (section === "filter") {
      if (this.state.filterOpen === true) {
        this.setState({ filterOpen: false });
        this.setState({ sortOpen: false });
      }
      if (this.state.filterOpen === false && this.state.sortOpen === false) {
        this.setState({ sortOpen: false });
        this.setState({ filterOpen: true });
      }
      if (this.state.filterOpen === false && this.state.sortOpen === true) {
        this.setState({ sortOpen: false });
        setTimeout(() => {
          this.setState({ filterOpen: true });
        }, 900);
      }
    }
    if (section === "sort") {
      if (this.state.sortOpen === true) {
        this.setState({ filterOpen: false });
        this.setState({ sortOpen: false });
      }
      if (this.state.sortOpen === false && this.state.filterOpen == false) {
        this.setState({ filterOpen: false });
        this.setState({ sortOpen: true });
      }
      if (this.state.sortOpen === false && this.state.filterOpen == true) {
        this.setState({ filterOpen: false });
        setTimeout(() => {
          this.setState({ sortOpen: true });
        }, 900);
      }
    }
  };

  render() {
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            zIndex: "100",
            top: this.props.positionTop?-1000:"calc(max(50px, 5vh)",
            paddingTop: "2vh",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100vw",
              backgroundColor: "transparent",
            }}
          >
            {/* <BackButton {...props} previousPage ="Home"/> */}
            <SearchField
              {...this.props}
              openOnlyOne={this.openOnlyOne}
              isOpen={this.state.filterOpen}
            />
          </div>
          <FilterOrderField
            handleChangeField={this.props.handleChangeField}
            heightFilter={this.state.filterOpen ? false : true}
            heightSort={this.state.sortOpen ? false : true}
          />
        </div>
      </Fragment>
    );
  }
}

export default SearchBox;
