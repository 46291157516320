import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import SingleSlide from "./SingleSlide";
import SingleSlideFeedback from "./SingleSlideFeedback";
// import races from "../mockupData/races";
import races from "../db/races.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class SimpleSlider extends Component {
  shouldComponentUpdate(oldProps, oldState) {
    return false;
  }

  render() {
    const { similar } = this.props;
    let settings = {
      dots: true,
      infinite: false,
      speed: 500,
      // slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false,
      dotsClass: this.props.dot,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            // slidesToShow: 3,
            // slidesToScroll: 3,
            // infinite: true,
            // dots: true
          },
        },
        {
          breakpoint: 600,
          settings: {
            // slidesToShow: 2,
            // slidesToScroll: 2,
            // initialSlide: 1
          },
        },
        {
          breakpoint: 480,
          settings: {
            // slidesToShow: 1.3,
            // slidesToScroll: 1
          },
        },
      ],
    };
    let randomIndexes = [
      Math.floor(Math.random() * 6000),
      Math.floor(Math.random() * 6000),
      Math.floor(Math.random() * 6000),
      Math.floor(Math.random() * 6000),
      Math.floor(Math.random() * 6000),
      Math.floor(Math.random() * 6000),
      Math.floor(Math.random() * 6000),
      Math.floor(Math.random() * 6000),
      Math.floor(Math.random() * 6000),
      Math.floor(Math.random() * 6000),
    ];
    return (
      <div
        style={{
          maxWidth: "100vw",
          paddingLeft: "0px",
          paddingRight: "0px",
          marginBottom: "6vh",
        }}
      >
        {!!this.props.feedback ? (
          <Fragment>
            <div
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                width: "100vw",
                textAlign: "center",
                color: "white",
                paddingTop: "1.5vh",
                paddingBottom: "1.5vh",
              }}
            >
              {this.props.titleText}
            </div>
            <Slider
              // autoplay
              autoplaySpeed={5000}
              infinite={true}
              // lazyLoad
              {...settings}
              slidesToShow={1}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                <SingleSlideFeedback {...this.props} />
              ))}
            </Slider>
          </Fragment>
        ) : !!this.props.run && !!this.props.similar ? (
          <div>
            <div className="sliderTitle">{this.props.titleText}</div>
            <Slider slidesToShow={1.2} {...settings}>
              {races
                .filter(
                  (race) =>
                    race["tipo2"] === this.props.similar["tipo2"] &&
                    race["nome"] !== this.props.similar["nome"]
                )
                .slice(0, 10)
                .map((item, index) => (
                  <SingleSlideFeedback
                    run
                    item={item}
                    length={races.length}
                    i={index}
                    {...this.props}
                  />
                ))}
            </Slider>
          </div>
        ) : (
          <div>
            <div className="sliderTitle">{this.props.titleText}</div>
            <Slider slidesToShow={1.2} {...settings}>
              {randomIndexes.map((item, index) => (
                <SingleSlideFeedback
                  run
                  item={races[item]}
                  length={races.length}
                  i={item}
                  {...this.props}
                />
              ))}
            </Slider>
          </div>
        )}

        <div
          style={{
            color: "lightgreen",
            textAlign: "center",
            paddingTop: "3vh",
            display: "none",
          }}
        >
          {this.props.altro}
        </div>
      </div>
    );
  }
}
