import React, { Fragment } from "react";
import Header from "../../component/Header";
import SimpleSlider from "../../component/SimpleSlider";
import Mappa from "../../component/Mappa.js";
import trail from "../../img/home/runType/newImage/TRAIL_CERCHIO Small.jpg";
import vertical from "../../img/home/runType/newImage/TRAIL_CERCHIO Vertical.jpg";
import utmb from "../../img/home/runType/newImage/UTMB_logo.jpg";
import sky from "../../img/home/runType/newImage/TRAIL_CERCHIO Sky.jpg";
import ultraM from "../../img/home/runType/newImage/TRAIL_CERCHIO Ultra M.jpg";
import ultraL from "../../img/home/runType/newImage/TRAIL_CERCHIO Ultra L.jpg";
import ultraXL from "../../img/home/runType/newImage/TRAIL_CERCHIO Ultra XL.jpg";
import all from "../../img/home/runType/newImage/TRAIL CERCHIO All.jpg";
import Footer from "../../component/Footer";
import FilterOrderField from "../../component/FilterOrderField";
import SearchBox from "../../component/SearchBox";
import RacePresentationSlider from "../../component/RacePresentationSlider";
import Banner from "../../component/Banner";
import { useSwipeable } from "react-swipeable";

import BottomMenu from "./BottomMenu";

const runType = [
  {
    text: "Small",
    value: "trail",
    img: trail,
  },
  {
    text: "Ultra M",
    value: "ultra_m",
    img: ultraM,
  },
  {
    text: "Ultra L",
    value: "ultra_l",
    img: ultraL,
  },
  {
    text: "Ultra XL",
    value: "ultra_xl",
    img: ultraXL,
  },
  {
    text: "Vertical",
    value: "vertical",
    img: vertical,
  },
  {
    text: "Sky",
    value: "sky",
    img: sky,
  },
  {
    text: "UTMB",
    value: "utmb",
    img: utmb,
  },
  
];

class Home extends React.Component {
  state = {
    showFooter: true,
    footerPosition: 0,
    searchBoxPosition: false
  };

  goToRace = (type) => {
    this.props.history.push("/SearchResult?type1=" + type.toLowerCase());
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldState.pagePosition !== this.state.pagePosition) {
      if (
        this.state.pagePosition < oldState.pagePosition &&
        Math.abs(this.state.pagePosition - oldState.pagePosition) > 16
      ) {
        this.setState({
          footerPosition: 0,
        });
      } else if (
        this.state.pagePosition > oldState.pagePosition &&
        Math.abs(this.state.pagePosition - oldState.pagePosition) > 16
      ) {
        this.setState({
          footerPosition: -80,
        });
      }
    }
  
    if (oldState.pagePosition !== this.state.pagePosition) {
      if (
        this.state.pagePosition < oldState.pagePosition &&
        Math.abs(this.state.pagePosition - oldState.pagePosition) > 16
      ) {
        this.setState({
          searchBoxPosition: true,
        });
      } else if (
        this.state.pagePosition > oldState.pagePosition &&
        Math.abs(this.state.pagePosition - oldState.pagePosition) > 16
      ) {
        this.setState({
          searchBoxPosition: false,
        });
      }
    }
  }

  handleScroll(e) {
    this.setState({
      pagePosition: window.pageYOffset,
    });
  }

  render() {
    return (
      <div>
        <Header {...this.props} />
        <SearchBox autocomplete {...this.props} positionTop={this.state.searchBoxPosition}/>
        <img className="backgroundHomeImage" />

        <div className="selectRunTypeContainer" style={{ paddingTop: 32 }}>
          <div className="selectRunText">SELEZIONA IL TIPO DI GARA</div>
          <div className={"selectRunType"} style={{paddingTop:18}}>
            <div
              onClick={() => this.goToRace("")}
              className="runTypeContainer"
            >
              {/*<div className="runTypeText" style={{ textAlign: "center" }}>*/}
              {/*  All*/}
              {/*</div>*/}
              <img src={all} alt="" className="runType" style={{width: '34vw'}} />
            </div>
          <div className="selectRunType" style={{ paddingTop: 0 }}>
            
            {runType.map((item, i) => (
              <div
                onClick={() => this.goToRace(item.value)}
                className="runTypeContainer"
              >
                {/*<div className="runTypeText" style={{ textAlign: "center" }}>*/}
                {/*  {item.text}*/}
                {/*</div>*/}
                <img
                  src={item.img}
                  alt=""
                  className="runType"
                  style={item.value === 'utmb' ? {width: '28vw', marginTop: 32} : {}} />
              </div>
            ))}
          </div>
        </div>
        </div>
        {/* <RacePresentationSlider run dot="slick-dots" titleText ="/ LE GARE PER TE /" {...this.props}/> */}
        <div
          style={{
            backgroundColor: "white",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            paddingBottom: "0vh",
          }}
        >
          <SimpleSlider
            run
            dot="slick-dots"
            titleText="LE GARE PER TE"
            altro="Vedi Di Più"
            {...this.props}
          />
        </div>
        <div className="mapContainer" style={{ margin: 0, overflow: "hidden" }}>
          <div className="sliderTitle" style={{ paddingBottom: "4%" }}>
            COSTRUISCI LA TUA RUNMAP
          </div>
          <div style={{fontSize:12, textAlign: "center",color: "black", paddingBottom:"2.6vh"}}>
            Non tenere i tuoi pettorali nel cassetto!<br/> Mappa le tue gara e registra i tuoi risultati
          </div>
          <Mappa
            defaultCenter={{
              lat: 45.694889,
              lng: 9.66996,
            }}
            height="45vh"
            width={"100vw"}
            zIndex={"auto"}
          />
        </div>
        {/* <RacePresentationSlider run dot="slick-dots" titleText ="/ POTREBBERO PIACERTI /" altro="Vedi Di Più" {...this.props}/> */}
        <div
          style={{
            backgroundColor: "white",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            paddingBottom: "0vh",
          }}
        >
          <SimpleSlider
            run
            dot="slick-dots"
            titleText="POTREBBERO PIACERTI"
            altro="Vedi Di Più"
            {...this.props}
          />
        </div>
        <Banner text hideBorder paddingTop={"32px"} paddingBottom={"0"} />
        {/* <RacePresentationSlider run dot="slick-dots" titleText ="/ VICINO A TE /" {...this.props} vicinoATe/> */}
        <div
          style={{
            backgroundColor: "white",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            paddingBottom: "0vh",
          }}
        >
          <SimpleSlider
            run
            dot="slick-dots"
            titleText="VICINO A TE"
            altro="Vedi Di Più"
            {...this.props}
          />
        </div>
        <Banner ads hideBorder paddingTop={"32px"} paddingBottom={"0"} />
        {/* <RacePresentationSlider run dot="slick-dots" titleText ="/ PETTORALI RUNVISOR /" altro="Vedi Di Più" {...this.props}/> */}
        <div
          style={{
            backgroundColor: "white",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            paddingBottom: "0vh",
          }}
        >
          <SimpleSlider
            run
            dot="slick-dots"
            titleText="PETTORALI RUNVISOR"
            altro="Vedi Di Più"
            {...this.props}
          />
        </div>
        <div
          style={{
            backgroundColor: "#414141",
            width: "100vw",
            marginTop: "6vh",
            display: "flex",
            alignItems: "center",
            paddingTop: "4vh",
            paddingBottom: "5.5vh",
          }}
        >
          <SimpleSlider
            dot="slick-dots2"
            feedback
            similar={false}
            titleText="RECENSIONI DEGLI UTENTI"
            altro="Vedi Di Più"
            {...this.props}
          />
        </div>

        {/* nav footer */}
        <BottomMenu
          showBottom={this.state.showFooter}
          position={this.state.footerPosition}
        />
        <Footer />
        {/*<div className="searchBoxContainerGlobal">
                    <div className="searchBoxContainer">
                        <div className="searchBox">Cerca una gara</div>
                        <div className="searchBoxArrow"/>
                    </div>
                </div>
                <Footer/>*/}
      </div>
    );
  }
}

export default Home;
