import React, { Fragment } from "react";
import {
  TransitionGroup,
  CSSTransition,
  Transition,
} from "react-transition-group";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class LandingThanks extends React.Component {
  state = {
    previousPage: this.props.previousPage,
    nextPage: this.props.nextPage,
    inProp: true,
    loading: true,
  };

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 2000);
    setTimeout(() => {
      this.nextIndex();
    }, 2500);
  };

  nextIndex = () => {
    this.setState({ animation: "fade" });
    setTimeout(() => {
      this.props.history.push("/" + this.props.nextPage+"?tipo1="+localStorage.distance+"&tipo2="+localStorage.landscape+"&elevation_max="+localStorage.elevation_max+"&elevation_min="+localStorage.elevation_min);
    }, 1000);
  };

  render() {
    return (
      <Fragment>
        <div className="landingButtonBackground"></div>
        <TransitionGroup>
          <CSSTransition
            classNames="fade"
            key={this.props.sectionName}
            timeout={{ enter: 500, exit: 500 }}
            in={this.state.inProp}
            unmountOnExit={true}
            appear
          >
            <div>
              <div className="containerThanks">
                <div className="thanksTitle">Grazie Runner</div>
                <div className="thanksSubtilte">
                  Stiamo trovando le gare più adatte a te ...
                </div>
                {this.state.loading ? (
                  <div style={{ padding: "16px 0" }}>
                    <Loader
                      type="TailSpin"
                      color="rgb(149, 214, 10)"
                      height={100}
                      width={100}
                      // timeout={3000} //3 secs
                    />
                  </div>
                ) : (
                  <div className="runvisorTick"></div>
                )}
                <div className="thanksText">
                  Prima di lasciarci, rivivi con noi qualche tua esperienza di
                  corsa. Lascia una recensione ad alcune gare a cui hai
                  partecipato
                </div>
              </div>
              <div className="bottomContainerThanks">
                {/* <div className="img1Thanks"/>
                                <div className="img2Thanks"/>
                                <div className="img3Thanks"/> */}
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}

export default LandingThanks;
