import React, { Fragment } from "react";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import {
  TransitionGroup,
  CSSTransition,
  Transition,
} from "react-transition-group";

class LandingLogin extends React.Component {
  state = {
    previousPage: this.props.previousPage,
    nextPage: this.props.nextPage,
    inProp: true,
  };

  nextIndex = () => {
    this.state.inProp = false;
    this.props.history.push("/" + this.props.nextPage);
  };
  prevIndex = () => {
    this.props.history.push("/" + this.props.previousPage);
  };

  render() {
    return (
      <Fragment>
        <div className="landingButtonBackground"></div>
        <TransitionGroup>
          <CSSTransition
            classNames="fade"
            key={this.props.sectionName}
            timeout={{ enter: 500, exit: 500 }}
            in={this.state.inProp}
            unmountOnExit={true}
            appear
          >
            <div style ={{maxHeight: window.innerHeight, overflow: "hidden" }}>
              <div className="loginContainer">
                <div className="loginText">
                  Effettuando l'accesso potrai migliorare la tua esperienza
                  d'uso e avere suggerimenti unici, in base alle tue esperienze
                  di ricerca
                </div>
                <GoogleLoginButton
                  style={{ width: "75vw", borderRadius: 16 }}
                />
                <div className="whitespace">space</div>
                <FacebookLoginButton
                  style={{ width: "75vw", borderRadius: 16 }}
                />
                <div className="loginOppure">-------OPPURE-------</div>
                <button className="loginButton">Login with Email</button>
                <div className="loginTerms">
                  Continuando, accetti i Termini di utilizzo e confermi di aver
                  letto la Normativa sulla privacy e sull'uso dei cockie
                </div>
              </div>
              <div
                className="backSkipContainerLogin"
                style={{ paddingBottom: "3.5vh" }}
              >
                <div className="backButton" onClick={this.prevIndex}>
                  Indietro
                </div>
                <div className="skipButton" onClick={this.nextIndex}>
                  Lo farò dopo
                </div>
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}

export default LandingLogin;
