import React, { Fragment } from "react";
import adsImage from "../img/searchResult/pexels-shane-aldendorff-786003.jpg";
import raceImage from "../img/dove_osano_le_aquile.jpg";

class Banner extends React.Component {
  goToRace = () => {
    window.location.href = "/Race/2138";
  };

  render() {
    return (
      <Fragment>
      {!this.props.ads ?
          <div
            style={{
              marginTop: this.props.paddingTop,
              paddingBottom: this.props.paddingBottom,
              position: "relative",
              display: "flex",
            }}
          >
            <img
              src={this.props.ads ? adsImage : raceImage}
              style={{
                width: "100vw",
                height: "300px",
                backgroundColor: "darkGrey",
                objectFit: "cover",
              }}
            />
            {!this.props.hideBorder && (
              <hr
                style={{
                  width: "84vw",
                  border: " 1px solid gainsboro",
                  borderRadius: "2px",
                  marginTop: "4.5vh",
                }}
              />
            )}
            {this.props.text && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "99%",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  onClick={this.goToRace}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 28,
                    width: 200,
                    textAlign: "right",
                    padding: 24,
                  }}
                >
                  DOVE OSANO LE AQUILE
                </div>
              </div>
            )}
          </div>
          :
          <div
            style={{
              marginTop: this.props.paddingTop,
              paddingBottom: this.props.paddingBottom,
              position: "relative",
              display: "flex",
            }}
          >
            <img
              src={this.props.ads ? adsImage : raceImage}
              style={{
                width: "49vw",
                height: "300px",
                backgroundColor: "darkGrey",
                objectFit: "cover",
              }}
            />
            <img
              src={this.props.ads ? adsImage : raceImage}
              style={{
                width: "49vw",
                height: "300px",
                backgroundColor: "darkGrey",
                objectFit: "cover",
                marginLeft: "2vw"
              }}
            />
            {!this.props.hideBorder && (
              <hr
                style={{
                  width: "84vw",
                  border: " 1px solid gainsboro",
                  borderRadius: "2px",
                  marginTop: "4.5vh",
                }}
              />
            )}
            {this.props.text && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "49%",
                  height: "99%",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  onClick={this.goToRace}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 28,
                    width: 200,
                    textAlign: "right",
                    padding: 24,
                  }}
                >
                  DOVE OSANO LE AQUILE
                </div>
              </div>
            )}
            {this.props.text && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: "51vw",
                  width: "49%",
                  height: "99%",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  onClick={this.goToRace}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 28,
                    width: 200,
                    textAlign: "right",
                    padding: 24,
                  }}
                >
                  DOVE OSANO LE AQUILE
                </div>
              </div>
            )}
          </div>
      }
      </Fragment>
    );
  }
}

export default Banner;
