import React, { Fragment, useState } from "react";
import equal from "deep-equal";
import moment from "moment";

import Mappa from "../../component/Mappa";
import Header from "../../component/Header";
import leftArrow from "../../img/searchResult/iconfinder_left_4829874.png";
import rightArrow from "../../img/searchResult/iconfinder_right_4829872.png";
import SingleRunResult from "./SingleRunResult";
import Banner from "../../component/Banner";
import "../../style/SearchResultStyle.css";
// import races from "../../mockupData/races";
import racesData from "../../db/races.json";
import { useSwipeable } from "react-swipeable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faFilter,
  faSort,
  faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons/index";
import Footer from "../../component/Footer";
import BackButton from "../../component/BackButton";
import SearchField from "../../component/SearchField";
import FilterOrderField from "../../component/FilterOrderField";
import SearchBox from "../../component/SearchBox";

const feedback = [
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
];

class SearchResult extends React.Component {
  state = {
    page: 1,
    showMap: false,
    races: [],
    pastRaces: [],
    show: "future",
    query: {
      text: "",
      type: "",
    },
  };

  componentDidMount() {
    const queryString = this.props.location.search.substring(1);
    console.log(this.props.location.search)
    // const query = JSON.parse(
    //   '{"' +
    //     decodeURI(queryString)
    //       .replace(/"/g, '\\"')
    //       .replace(/&/g, '","')
    //       .replace(/=/g, '":"') +
    //     '"}'
    // );
    const query = getAllUrlParams(this.props.location.search)
    console.log('query', query)

    const races = racesData
      .filter((race) => Date.parse(race["Data"]))
      .filter(
        (race) =>
          new Date(race["Data"]).toISOString() >= new Date().toISOString()
      )
      .sort((a, b) =>
        new Date(a["Data"]).toISOString() < new Date(b["Data"]).toISOString()
          ? -1
          : 1
      );

    const pastRaces = racesData
      .filter((race) => Date.parse(race["Data"]))
      .filter(
        (race) =>
          new Date(race["Data"]).toISOString() < new Date().toISOString()
      )
      .sort((a, b) =>
        new Date(a["Data"]).toISOString() < new Date(b["Data"]).toISOString()
          ? -1
          : 1
      );

    this.setState({
      races,
      pastRaces,
      racesBak: races,
      pastRacesBak: pastRaces,
      pages: Math.ceil(races.length / 10),
      query,
    });
  }

  componentDidUpdate(oldProps, oldState) {
    if (!equal(oldState.query, this.state.query)) {
      const { query } = this.state;
      const races = this.filterRaces(this.state.racesBak, query);
      const pastRaces = this.filterRaces(this.state.pastRacesBak, query);
      this.setState({
        races,
        pastRaces,
        pages:
          this.state.show === "future"
            ? Math.ceil(races.length / 10)
            : Math.ceil(pastRaces.length / 10),
        page: 1,
      });
    }
  }

  filterRaces = (races, query) => {
    return races
      .filter((race) => Date.parse(race["Data"]))
      .filter((race) =>
        !!query.type1 && query.type1 !== ""
          ? !!race.tipo1 && race.tipo1.toLowerCase().includes(query.type1.toLowerCase())
          : true
      )
      .filter((race) =>
        !!query.type2 && query.type2 !== ""
          ? !!race.tipo2 && race.tipo2.toLowerCase().includes(query.type2.toLowerCase())
          : true
      )
      .filter((race) =>
        !!query.text && query.text !== ""
          ? race["nome"] && race["nome"].toLowerCase().includes(query.text.toLowerCase()) ||
            race["Luogo"].toLowerCase().includes(query.text.toLowerCase()) ||
            race["Provincia"]
              .toLowerCase()
              .includes(query.text.toLowerCase()) ||
            race["Regione"].toLowerCase().includes(query.text.toLowerCase()) ||
            (!!race["tipo1"] && race["tipo1"].toLowerCase().includes(query.text.toLowerCase())) ||
            (!!race["tipo2"] && race["tipo2"].toLowerCase().includes(query.text.toLowerCase())) ||
            (!!race["tipo3"] && race["tipo3"].toLowerCase().includes(query.text.toLowerCase())) ||
            (!!race["home sito"] &&
              race["home sito"]
                .toLowerCase()
                .includes(query.text.toLowerCase())) ||
            (!!race["Organizzatore"] &&
              race["Organizzatore"]
                .toLowerCase()
                .includes(query.text.toLowerCase()))
          : true
      )
      .filter((race) =>
        !!query.date && query.date !== ""
          ? new Date(race["Data"]).toISOString() <=
            new Date(moment().add(query.date, "days")).toISOString()
          : true
      )
      .filter((race) =>
        !!query.distance
          ? race["Lunghezza (km)"] >= query.distance.min &&
            race["Lunghezza (km)"] <= query.distance.max
          : true
      )
      .filter((race) =>
        !!query.elevation
          ? (race["Dislivello (D+)"] >= (Number(query.elevation.min) || 0)) &&
            (race["Dislivello (D+)"] <= (Number(query.elevation.max) || 100000))
          : true
      )
      .filter((race) =>
        !!query.rating && query.rating !== ""
          ? race.rating.general >= Number(query.rating) * 20
          : true
      )
      .sort((a, b) => {
        switch (query.sort) {
          case "date":
            return new Date(a["Data"]).toISOString() <
              new Date(b["Data"]).toISOString()
              ? -1
              : 1;
          case "distance":
            return a["Lunghezza (km)"] < b["Lunghezza (km)"] ? -1 : 1;
          case "elevation":
            return a["Dislivello (D+)"] > b["Dislivello (D+)"] ? -1 : 1;
          case "rating":
            return a.rating.general > b.rating.general ? -1 : 1;
          case "price":
            return a["Costo"] < b["Costo"] ? -1 : 1;
          case "numpettorali":
            return a["numPettorali"] > b["numPettorali"] ? -1 : 1;
          case "numreviews":
            return a["numReviews"] > b["numReviews"] ? -1 : 1;
          default:
            return new Date(a["Data"]).toISOString() <
              new Date(b["Data"]).toISOString()
              ? -1
              : 1;
        }
      });
  };

  handleSwipe = (status) => {
    this.setState({
      showMap: status,
    });
  };

  handleChangePage = (delta) => {
    const { page, pages } = this.state;
    if ((page > 1 && delta === -1) || (page <= pages && delta === 1)) {
      this.setState({
        page: page + delta,
      });
      window.scrollTo(0, 0);
    }
  };

  handleChangeText = (val) => {
    this.setState({
      query: {
        ...this.state.query,
        text: val,
      },
    });
  };

  handleChangeQueryField = (field, value) => {
    this.setState({
      query: {
        ...this.state.query,
        [field]: value,
      },
    });
  };

  handleShowRace = (time) => {
    this.setState({
      show: time,
      pages:
        time === "future"
          ? Math.ceil(this.state.races.length / 10)
          : Math.ceil(this.state.pastRaces.length / 10),
      page: 1,
    });
  };

  render() {
    const props = this.props;
    const { showMap, page, pages, races, pastRaces } = this.state;

    console.log(this.state);
    const toShow = this.state.show === "future" ? races : pastRaces;
    return (
      <Fragment>
        <Header {...props} />
        <SearchBox
          value={this.state.query.text}
          handleChangeText={this.handleChangeText}
          handleChangeField={this.handleChangeQueryField}
          withFilters
          showFilter={this.state.showFilter}
          showSort={this.state.showSort}
          {...props}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
          }}
        >
          <div
            className={
              showMap ? "mapContainerSearch " : "mapContainerSearch collapsed "
            }
          >
            <Mappa height={"100vh"} width={"100vw"} zIndex={"auto"} />
          </div>
          <div
            style={{
              borderRadius: "20px",
              backgroundColor: "white",
              marginTop: "-10px",
              zIndex: "50",
            }}
          >
            <SwipeDown
              handleSwipe={this.handleSwipe}
              showMap={this.state.showMap}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginBottom: 16,
              }}
            >
              <div
                onClick={() =>
                  this.setState({ showFilter: !this.state.showFilter })
                }
                style={{ color: "#333", marginRight: 16, marginLeft: 16 }}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  style={{ fontSize: 10, color: "#333", paddingRight: 4 }}
                />
                Filtra
              </div>
              <div
                onClick={() =>
                  this.setState({ showSort: !this.state.showSort })
                }
                style={{ color: "#333", marginRight: 16 }}
              >
                <FontAwesomeIcon
                  icon={faSortAmountDown}
                  style={{ fontSize: 12, color: "#333", paddingRight: 4 }}
                />
                Ordina
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 16px",
                marginBottom: 24,
              }}
            >
              <div
                onClick={() => this.handleShowRace("past")}
                style={{
                  borderRadius: "24px 24px 0 0px",
                  boxShadow:
                    this.state.show === "past"
                      ? "0px 10px 5px #fff, 0px 0px 8px #888"
                      : "inset 0px -5px 5px -5px #888",
                  padding: "16px 0",
                  flex: 1,
                  textAlign: "center",
                  fontWeight: this.state.show === "past" ? "bold" : "unset",
                }}
              >
                {pastRaces.length} gare passate
              </div>
              <div
                onClick={() => this.handleShowRace("future")}
                style={{
                  borderRadius: "24px 24px 0 0px",
                  boxShadow:
                    this.state.show === "future"
                      ? "0px 10px 5px #fff, 0px 0px 8px #888"
                      : "inset 0px -5px 5px -5px #888",
                  padding: "16px 0",
                  flex: 1,
                  textAlign: "center",
                  fontWeight: this.state.show === "future" ? "bold" : "unset",
                }}
              >
                {races.length} gare trovate
              </div>
            </div>

            {toShow
              // .filter(race => race.tipo2 === query.type)
              .slice((page - 1) * 10, page * 10)
              .map((item, index) => {
                if (index % 4 === 0)
                  return (
                    <Fragment>
                      <SingleRunResult
                        feedback={feedback}
                        race={item}
                        {...props}
                      />
                      <Banner ads paddingTop={"0"} paddingBottom={"4.5vh"} />
                    </Fragment>
                  );
                else
                  return (
                    <SingleRunResult
                      feedback={feedback}
                      race={item}
                      {...props}
                    />
                  );
              })}
            {toShow.length === 0 && (
              <div style={{ textAlign: "center", padding: 32 }}>
                Nessuna gara corrisponde ai criteri di ricerca
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                width: "100vw",
                paddingTop: "3.5vh",
                paddingBottom: "4.5vh",
              }}
            >
              <div
                onClick={() => this.handleChangePage(-1)}
                style={{
                  width: "46px",
                  height: "46px",
                  borderRadius: "50%",
                  border: "0px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className={`${
                  page === 1 ? "opacityDown" : "circleShadow"
                } circleTransition`}
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  style={{ height: 28, width: 28 }}
                  className={"iconSingleResult"}
                />
              </div>
              <div
                onClick={() => this.handleChangePage(1)}
                style={{
                  width: "46px",
                  height: "46px",
                  borderRadius: "50%",
                  border: "0px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className={`${
                  page >= pages ? "opacityDown" : "circleShadow"
                } circleTransition`}
              >
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{ height: 28, width: 28 }}
                  className={"iconSingleResult"}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

const SwipeDown = (props) => {
  const handlers = useSwipeable({
    onSwipedDown: () => props.handleSwipe(true),
    onSwipedUp: () => props.handleSwipe(false),
    // preventDefaultTouchmoveEvent:false,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    delta: 0,
  });
  return (
    <div
      style={{
        paddingTop: "3.5vh",
        paddingBottom: "3vh",
        touchAction: "pan-y",
      }}
      {...handlers}
      onClick={() => props.handleSwipe(!props.showMap)}
    >
      <hr
        style={{
          width: "16vw",
          border: " 1px solid grey",
          borderRadius: "2px",
          marginTop: "0vh",
          marginBottom: "0vh",
        }}
      />
    </div>
  );
};

export default SearchResult;

function getAllUrlParams(url) {

  // get query string from url (optional) or window
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {

    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      if (paramName.includes('_')) {
        const names = paramName.split('_')
        const main = names[0]
        const sub = names[1]

        var key = main.replace(/\[(\d+)?\]/, '');
        var subKey = sub.replace(/\[(\d+)?\]/, '');
        
        if (!obj[key]) obj[key] = {};

        if (!obj[key][subKey]) obj[key][subKey] = paramValue

      } else if (paramName.match(/\[(\d+)?\]$/)) {
        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string'){
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}