import React, { Fragment } from "react";
import {
  faChevronLeft,
  faFilter,
  faSearch,
  faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import races from "../db/races.json";

const smallFontSize = "12px";
const mediumFontSize = "16px";
const bigFontSize = "18px";
const veryBigFontSize = "24px";

class SearchField extends React.Component {
  state = {
    filter: "",
  };

  handleGoToPage = () => {
    this.props.history.push(
      `/SearchResult?type1=${
        !!this.state.filter ? "&text=" + this.state.filter : ""
      }`
    );
  };

  changeText = (ev) => {
    this.setState({
      filter: ev.target.value,
    });
    if (this.props.handleChangeText) {
      this.props.handleChangeText(ev.target.value);
    }
  };

  goToRace = (id) => {
    this.props.history.push("/Race/" + id);
  };

  render() {
    // console.log(this.state)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "70vw",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "70vw",
              padding: "0 24px",
              height: "48px",
              borderRadius: "24px",
              border: "1px solid #ddd",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // marginLeft:"1vw",
              backgroundColor: "white",
            }}
            className="circleTransition"
          >
            <input
              onChange={this.changeText}
              value={this.props.value || this.state.filter}
              type="text"
              style={{
                // maxWidth:"36vw",
                border: "none",
                height: "40px",
                borderRadius: "15px",
              }}
              placeholder="Cerca la tua gara..."
              // onClick={this.goToPage}
            />
            {this.props.withFilters ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{ borderLeft: "1px solid black", height: "28px" }}
                />
                <div
                  style={{ marginLeft: 8 }}
                  onClick={() => this.props.openOnlyOne("filter")}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                    style={{ height: "18px", width: "18px" }}
                    className={"iconSingleResult"}
                  />
                </div>
                <div
                  style={{ marginLeft: 8 }}
                  onClick={() => this.props.openOnlyOne("sort")}
                >
                  <FontAwesomeIcon
                    icon={faSortAmountDown}
                    style={{ height: "18px", width: "18px" }}
                    className={"iconSingleResult"}
                  />
                </div>
              </div>
            ) : (
              <div onClick={this.handleGoToPage}>
                <FontAwesomeIcon
                  icon={faSearch}
                  // size={24}
                  style={{
                    height: 16,
                    width: 16,
                    // color: 'rgb(149, 214, 10)'
                  }}
                  className={"iconSingleResult"}
                />
              </div>
            )}
          </div>
        </div>
        {!!this.props.autocomplete &&
          !!this.state.filter &&
          this.state.filter !== "" &&
          this.state.filter.length > 3 && (
            <div
              style={{
                background: "white",
                marginTop: 8,
                padding: "16px 24px",
                borderRadius: 24,
                // boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.75)',
                border: "1px solid #ddd",
                minWidth: "70vw",
                maxWidth: "70vw",
                maxHeight: 200,
                overflow: "scroll",
              }}
            >
              {races
                .filter((race) =>
                  race["nome"].toLowerCase().includes(this.state.filter)
                )
                .map((race) => (
                  <div
                    onClick={() => this.goToRace(race["id"])}
                    style={{
                      padding: "8px",
                      borderBottom: "1px solid #eee",
                      fontSize: 14,
                    }}
                  >
                    {race["nome"]}
                  </div>
                ))}
              {races.filter((race) =>
                race["nome"].toLowerCase().includes(this.state.filter)
              ).length === 0 && (
                <div style={{ fontSize: 14 }}>Nessuna gara trovata</div>
              )}
            </div>
          )}
      </div>
    );
  }
}

export default SearchField;
