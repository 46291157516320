import React, { Fragment } from "react";
import calendario from "../img/home/Calendar.svg";
import pennina from "../img/home/Pennina.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning, faStar } from "@fortawesome/free-solid-svg-icons";
import "../style/SearchResultStyle.css";
import DotFeedback from "./DotFeedback";

class SingleSlide extends React.Component {
  state = {
    run: false,
    pref: false,
  };

  goToRace = () => {
    this.props.history.push("/Race");
  };

  render() {
    return (
      <Fragment>
        <div className="slideContainer" onClick={this.goToRace}>
          <img
            src={require("../img/landing/newImage/Depositphotos_PAESAGGIO lago.jpg")}
            alt=""
            style={{ objectFit: "cover" }}
            className="singleSlideImage"
          />
          <div className="slideTextContainer">
            <div className="slideDate">18/10/2020</div>
            <div className="slideRunName">Orobie Ultra Trail</div>
            <div className="runDistanceDifferenceContainer">
              <div className="slideRunDistance">160km</div>
              <div className="slideRunDifference">
                1000 m <b>D+</b>
              </div>
            </div>
          </div>
          <div className="iconAndFeedbackContainer">
            <div className="iconContainer">
              <div onClick={() => this.setState({ run: !this.state.run })}>
                <FontAwesomeIcon
                  icon={faRunning}
                  style={{
                    height: "25px",
                    width: "25px",
                    paddingRight: "0.5vw",
                  }}
                  className={
                    this.state.run
                      ? "iconSingleResult"
                      : "iconSingleResult opacityDown"
                  }
                />
              </div>
              <div onClick={() => this.setState({ pref: !this.state.pref })}>
                <FontAwesomeIcon
                  icon={faStar}
                  style={{ height: "25px", width: "25px" }}
                  className={
                    this.state.pref
                      ? "iconSingleResult"
                      : "iconSingleResult opacityDown"
                  }
                />
              </div>
            </div>
            <div className="feedbackContainer">
              <DotFeedback />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SingleSlide;
