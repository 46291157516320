import React, { Fragment } from "react";
import { FacebookIcon, TwitterIcon } from "react-share";
import instagram from "../img/home/instagram-icon-8.svg";
import DotFeedback from "./DotFeedback";
import moment from "moment";
import img1 from "../img/landing/men-1245982_1920.jpg";
import img2 from "../img/landing/trail1.jpg";
import { Carousel } from "react-responsive-carousel";

const smallFontSize = "12px";
const mediumFontSize = "16px";
const bigFontSize = "18px";
const veryBigFontSize = "24px";

const feedback = {
  title: "Incredibile!",
  date: "2020-12-10T00:52:03.751Z",
  name: "lavaleeh",
  reviewText:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui nunc mattis enim ut tellus elementum sagittis vitae et. Vel pharetra vel turpis nunc eget. Volutpat sed cras ornare arcu. Fames ac turpis egestas maecenas pharetra convallis posuere morbi leo. Erat velit scelerisque in dictum non consectetur. Cras fermentum odio eu feugiat pretium nibh. Sed pulvinar proin gravida hendrerit lectus. Imperdiet proin fermentum leo vel orci porta non. Sit amet purus gravida quis. Condimentum mattis pellentesque id nibh tortor id aliquet lectus. Egestas sed tempus urna et pharetra pharetra massa massa ultricies.",
};

const img = [
  {
    name: "Race1",
    src: img1,
  },
  {
    name: "Race2",
    src: img2,
  },
  {
    name: "Race3",
    src: img1,
  },
  {
    name: "Race4",
    src: img2,
  },
  {
    name: "Race5",
    src: img1,
  },
  {
    name: "img6",
    src: img2,
  },
];

class Feedback extends React.Component {
  state = {
    isShrinked: true,
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100vw",
          alignItems: "center",
          paddingTop: "4vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "86vw",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: bigFontSize, fontWeight: "bold" }}>
            {feedback.title}
          </div>
          <div>
            <FacebookIcon
              size={24}
              round={true}
              style={{ cursor: "pointer", marginRight: 4 }}
            />
            <img
              className="instagram"
              src={instagram}
              height={24}
              style={{ cursor: "pointer", marginRight: 4 }}
            />
            <TwitterIcon size={24} round={true} style={{ cursor: "pointer" }} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "86vw",
            alignItems: "center",
            paddingTop: "1.6vh",
          }}
        >
          <DotFeedback />
          <div style={{ paddingLeft: "2.5vw", fontSize: mediumFontSize }}>
            {moment(feedback.date).format("DD/MM/YYYY")}
          </div>
        </div>
        <div
          style={{
            width: "86vw",
            fontSize: bigFontSize,
            paddingBottom: "1.6vh",
            paddingTop: "1.6vh",
          }}
        >
          {feedback.name}
        </div>
        <div
          className={this.state.isShrinked ? "textShrink" : ""}
          style={{
            width: "86vw",
            fontSize: smallFontSize,
            marginBottom: "0.5vh",
          }}
        >
          {feedback.reviewText}
        </div>
        <div
          style={{
            width: "86vw",
            fontSize: smallFontSize,
            marginBottom: "2vh",
            fontWeight: "bold",
            cursor: "pointer",
          }}
          onClick={() => this.setState({ isShrinked: !this.state.isShrinked })}
        >
          {this.state.isShrinked ? "Mostra di più" : "Mostra di meno"}
        </div>
        <Carousel
          className={"carousel4"}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          {img.map((item, index) => (
            <img
              src={item.src}
              alt=""
              style={{ height: "30vh", width: "86vw", objectFit: "cover" }}
            />
          ))}
        </Carousel>
      </div>
    );
  }
}

export default Feedback;
