import React, { Fragment } from "react";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import {
  TransitionGroup,
  CSSTransition,
  Transition,
} from "react-transition-group";
import SlideTracker from "../../component/SlideTracker";

class Landing123 extends React.Component {
  state = {
    previousPage: this.props.previousPage,
    nextPage: this.props.nextPage,
    inProp: true,
    animation: "fade",
  };

  nextIndex = () => {
    this.state.inProp = false;
    this.setState({ animation: "fadeInslideFromDx" });
    this.props.history.push("/" + this.props.nextPage);
  };

  prevIndex = () => {
    this.setState({ animation: "fadeInslideFromSx" });
    this.props.history.push("/" + this.props.previousPage);
  };

  render() {
    const data = [
      {
        text1: "CERCA LA TUA GARA,<br/> CORRI LA TUA GARA",
        text2: "Ogni runner si merita le gare più belle",
      },
      {
        text1: "PIANIFICA LE TUE CORSE,<br/> INVITA GLI AMICI",
        text2: "Aggiorna il tuo calendario e condividilo con gli amici",
      },
      {
        text1: "PIANIFICA LE TUE CORSE,<br/> INVITA GLI AMICI",
        text2: "Aggiorna il tuo calendario e condividilo con gli amici",
      },
    ];
    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            classNames={this.state.animation}
            key={this.props.sectionName}
            timeout={{ enter: 500, exit: 500 }}
            in={this.state.inProp}
            unmountOnExit={true}
            appear
          >
            <div>
              <div className="greyOverlay">
                <div className={this.props.sectionName} />
              </div>

              <div className="globalTextContainer">
                <div className="containerText1">
                  {this.props.landing1 ? (
                    <Fragment>
                      <div className="text1">
                        CERCA LA TUA GARA,
                        <br /> CORRI LA TUA GARA
                      </div>
                      <div className="text2">
                        Ogni runner si merita le gare più belle
                      </div>
                    </Fragment>
                  ) : (
                    <div className="hiddenElement"></div>
                  )}
                  {this.props.landing2 ? (
                    <Fragment>
                      <div className="text1" style={{ textAlign: "right" }}>
                        PIANIFICA LE TUE CORSE,
                        <br /> INVITA GLI AMICI
                      </div>
                      <div className="text2">
                        Aggiorna il tuo calendario e condividilo con gli amici
                      </div>
                    </Fragment>
                  ) : (
                    <div className="hiddenElement"></div>
                  )}
                  {this.props.landing3 ? (
                    <Fragment>
                      <div className="text1">
                        RECENSISCI LA TUA GARA,
                        <br /> AIUTA ALTRI RUNNER
                      </div>
                      <div className="text2">
                        Ogni runner è unico come le corse a cui partecipa
                      </div>
                    </Fragment>
                  ) : (
                    <div className="hiddenElement"></div>
                  )}
                </div>
              </div>
              {this.props.landing3 ? (
                <Fragment>
                  <div className="containerButtonAndText">
                    <button className="landing3Button" onClick={this.nextIndex}>
                      <div>Trova le tue gare</div>
                    </button>
                    <div className="landing3ExtraText">
                      Rispondi a poche domande, troveremo le corse più adatte a
                      te
                    </div>
                  </div>
                </Fragment>
              ) : (
                <div className="hiddenElement"></div>
              )}
            </div>
          </CSSTransition>
        </TransitionGroup>
        <TransitionGroup>
          <CSSTransition
            classNames={this.state.animation}
            key={this.props.sectionName}
            timeout={{ enter: 500, exit: 500 }}
            in={this.state.inProp}
            unmountOnExit={true}
            appear
          >
            <div className="slideTrackerContainer">
              <div className="dotContainer">
                <SlideTracker {...this.props} />
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>

        <ReactScrollWheelHandler
          timeout={600}
          leftHandler={this.nextIndex}
          rightHandler={this.prevIndex}
          customStyle={{
            width: "100vw",
            height: "100vh",
          }}
        />
      </Fragment>
    );
  }
}

export default Landing123;
