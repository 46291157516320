import React, { Fragment } from "react";
import "../style/hamburgers.css";

class Burger extends React.Component {
  state = {
    menuOpen: false,
  };
  changeMenuState = () => {
    if (!this.state.menuOpen) {
      this.setState({ menuOpen: true }, () => this.props.setIsOpen(true));
    } else {
      this.setState({ menuOpen: false }, () => this.props.setIsOpen(false));
    }
    this.props.setIsOpen({ ...this.state });
  };
  render() {
    return (
      <Fragment>
        <button
          className={
            this.state.menuOpen
              ? "hamburger hamburger--collapse is-active"
              : "hamburger hamburger--collapse"
          }
          type="button"
          onClick={this.changeMenuState}
          style={{ outline: "none" }}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </Fragment>
    );
  }
}

export default Burger;
