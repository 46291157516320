import React, { Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Landing123 from "../routes/landing/Landing123";
import LandingButton from "../routes/landing/LandingButton";
import LandingPosition from "../routes/landing/LandingPosition";
import LandingLogin from "../routes/landing/LandingLogin";
import LandingThanks from "../routes/landing/LandingThanks";
import Home from "../routes/home/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SimpleSlider from "./SimpleSlider";
import SingleSlide from "./SingleSlide";
import Mappa from "./Mappa";
import Landing from "../routes/landing/Landing";
import Footer from "./Footer";
import SingleSlideFeedback from "./SingleSlideFeedback";
import Burger from "./Burger";
import MinusButton from "./MinusButton";
import Header from "./Header";
import SearchResult from "../routes/searchResult/SearchResult";
import SingleRun from "../routes/searchResult/SingleRun";
import FilterOrderField from "./FilterOrderField";
import SingleRunFeedbackDisplay from "../routes/searchResult/SingleRunFeedbackDisplay";
import LoginPage from "../routes/login/LoginPage";
import Recensione from "../routes/recensione/Recensione";

class Router extends React.Component {
  state = {
    distance: null,
    landscape: null,
    difference: null,
    far: null,
  };

  setLandingTick = (data) => {
    this.setState({ ...this.state, ...data });
  };

  render() {
    console.log(this.state);
    return (
      <BrowserRouter>
        {() => this.setState({ locationSwitch: window.location })}
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <Landing
                landing1
                {...props}
                nextPage="Landing2"
                previousPage=""
                sectionName="landing1"
              />
            )}
          />
          <Route
            exact
            path="/Distance"
            render={(props) => (
              <LandingButton
                options={"distance"}
                {...props}
                setLandingTick={this.setLandingTick}
                nextPage="Landscape"
                previousPage="Distance"
                sectionName="distance"
                distance1={this.state.distance}
                landscape1={this.state.landscape}
                difference1={this.state.difference}
                far1={this.state.far}
              />
            )}
          />
          <Route
            exact
            path="/Landscape"
            render={(props) => (
              <LandingButton
                options={"landscape"}
                {...props}
                setLandingTick={this.setLandingTick}
                nextPage="Difference"
                previousPage="Distance"
                sectionName="landscape"
                distance1={this.state.distance}
                landscape1={this.state.landscape}
                difference1={this.state.difference}
                far1={this.state.far}
              />
            )}
          />
          <Route
            exact
            path="/Difference"
            render={(props) => (
              <LandingButton
                options={"difference"}
                {...props}
                setLandingTick={this.setLandingTick}
                nextPage="NearOrFar"
                previousPage="Landscape"
                sectionName="difference"
                distance1={this.state.distance}
                landscape1={this.state.landscape}
                difference1={this.state.difference}
                far1={this.state.far}
              />
            )}
          />
          <Route
            exact
            path="/NearOrFar"
            render={(props) => (
              <LandingButton
                options={"nearOrFar"}
                {...props}
                setLandingTick={this.setLandingTick}
                nextPage="Position"
                previousPage="Difference"
                sectionName="nearOrFar"
                distance1={this.state.distance}
                landscape1={this.state.landscape}
                difference1={this.state.difference}
                far1={this.state.far}
              />
            )}
          />
          <Route
            exact
            path="/Position"
            render={(props) => (
              <LandingPosition
                position
                {...props}
                nextPage="Login"
                previousPage="NearOrFar"
                sectionName="position"
              />
            )}
          />
          <Route
            exact
            path="/Login"
            render={(props) => (
              <LandingLogin
                login
                {...props}
                nextPage="LandingThanks"
                previousPage="Position"
                sectionName="login"
              />
            )}
          />
          <Route
            exact
            path="/LandingThanks"
            render={(props) => (
              <LandingThanks
                thanks
                {...props}
                nextPage="SearchResult"
                previousPage="Login"
                sectionName="thanks"
              />
            )}
          />
          <Route
            exact
            path="/Home"
            render={(props) => (
              <Home
                home
                {...props}
                nextPage="Home"
                previousPage="Home"
                sectionName="home"
              />
            )}
          />
          <Route
            exact
            path="/SearchResult"
            render={(props) => (
              <SearchResult {...props} sectionName="SearchResult" />
            )}
          />
          <Route
            exact
            path="/Race"
            render={(props) => <SingleRun {...props} sectionName="Race" />}
          />
          <Route
            exact
            path="/Race/:id"
            render={(props) => <SingleRun {...props} sectionName="Race" />}
          />
          <Route
            exact
            path="/LoginRegister"
            render={(props) => (
              <LoginPage {...props} sectionName="LoginRegister" />
            )}
          />
          <Route
            exact
            path="/Recensione"
            render={(props) => <Recensione {...props}  sectionName="Race" />}
          />

          <Route
            exact
            path="/Prova"
            render={(props) => (
              <Recensione
                home
                {...props}
                nextPage="Home"
                previousPage="Home"
                sectionName="home"
              />
            )}
          />
          <Route
            exact
            path="/Prova2"
            render={(props) => (
              <SingleRun
                home
                {...props}
                nextPage="Home"
                previousPage="Home"
                sectionName="home"
              />
            )}
          />
          <Route
            exact
            path="/Prova3"
            render={(props) => (
              <SingleRunFeedbackDisplay
                home
                {...props}
                nextPage="Home"
                previousPage="Home"
                sectionName="home"
              />
            )}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Router;
