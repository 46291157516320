import React, { Fragment } from "react";
import Feedback from "../../component/Feedback";

const feedback = [{}, {}, {}, {}, {}, {}];
const smallFontSize = "12px";
const mediumFontSize = "16px";
const bigFontSize = "18px";
const veryBigFontSize = "24px";

class SingleRunFeedbackDisplay extends React.Component {
  state = {
    showMore: false,
  };

  showFeedback = () => {
    if (!this.state.showMore)
      return (
        <Fragment>
          <Feedback />
          <Feedback />
          <Feedback />
        </Fragment>
      );
    else
      return (
        <Fragment>
          {feedback.map((item, index) => (
            <Feedback />
          ))}
        </Fragment>
      );
  };

  render() {
    return (
      <Fragment>
        <div>{this.showFeedback()}</div>
        <div
          style={{
            paddingLeft: "0vw",
            fontWeight: "bold",
            fontSize: "14px",
            paddingTop: "1.5vh",
          }}
          onClick={() => this.setState({ showMore: !this.state.showMore })}
        >
          {this.state.showMore ? "Mostra di meno" : "Mostra di più"}
        </div>
      </Fragment>
    );
  }
}

export default SingleRunFeedbackDisplay;
