import React, { Fragment } from "react";
import { Carousel } from "react-responsive-carousel";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import img1 from "../../img/landing/newImage/Depositphotos_PAESAGGIO lago.jpg";
import img2 from "../../img/landing/newImage/Depositphotos_PAESAGGIO montagna.jpg";
import posizione from "../../img/searchResult/iconfinder_icons_pin_1564524 (2).png";
import elevation from "../../img/searchResult/20201126001345-75081-profile.png";
import DotFeedback from "../../component/DotFeedback";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faRunning,
  faStar,
  faClipboard,
  faClipboardCheck,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Mappa from "../../component/Mappa";
import SimpleSlider from "../../component/SimpleSlider";
import SingleRunFeedbackDisplay from "./SingleRunFeedbackDisplay";
import RacePresentationSlider from "../../component/RacePresentationSlider";
import races from "../../db/races.json";
import RaceDetail from "../../component/RaceDetail";

const smallFontSize = "12px";
const mediumFontSize = "16px";
const bigFontSize = "18px";
const veryBigFontSize = "24px";

const feedbacktype = [
  { name: "paesaggio" },
  { name: "organizzazione" },
  { name: "ristori" },
  { name: "pacco gara" },
  { name: "prezzo" },
];

const images = [
  {
    name: "img1",
    src: img1,
    legend: "30 euro fino al 1/1/2021",
  },
  {
    name: "img2",
    src: img2,
    legend: "50 euro fino al 1/2/2021",
  },
  {
    name: "img3",
    src: img1,
    legend: "80 euro fino al 1/3/2021",
  },
  {
    name: "img4",
    src: img2,
    legend: "Se hai corso questa gara, lascia una recensione!",
  },
  {
    name: "img5",
    src: img1,
    legend: "",
  },
  {
    name: "img6",
    src: img2,
    legend: "",
  },
];

// const race =
//     {
//         name: "Orobie Ultra Trail",
//         date: "2020-10-16T00:52:03.751Z",
//         distance: "160",
//         elevation: "1000",
//         feedback: "5",
//         typology: "trail",
//         website: "link al website",
//         price:"80 euro",
//         runvisorRemaining: "10"
//     }
// ;

const sameDay = [
  {
    name: "Race1",
    src: img1,
  },
  {
    name: "Race2",
    src: img2,
  },
  {
    name: "Race3",
    src: img1,
  },
  {
    name: "Race4",
    src: img2,
  },
  {
    name: "Race5",
    src: img1,
  },
  {
    name: "img6",
    src: img2,
  },
];

const recensioni = [
  {
    name: "Paolo rossi",
    title: "Incredibile",
    text:
      '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    src: img1,
  },
  {
    name: "Paolo rossi",
    title: "Incredibile",
    text:
      '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    src: img1,
  },
  {
    name: "Paolo rossi",
    title: "Incredibile",
    text:
      '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    src: img1,
  },
  {
    name: "Paolo rossi",
    title: "Incredibile",
    text:
      '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    src: img1,
  },
  {
    name: "Paolo rossi",
    title: "Incredibile",
    text:
      '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    src: img1,
  },
];

const circle = {
  border: "solid",
  height: 48,
  width: 48,
  borderRadius: "50%",
  borderWidth: 1,
  borderColor: "rgb(149, 214, 10)",
  // boxShadow: "2px 2px 2px 0px rgba(0,0,0,0.4)",
  padding: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const feedback = [
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
];

const regionRun = [
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
];

class SingleRun extends React.Component {
  state = {
    isShrinked: true,
    isExpanded: "carousel3",
    imgHeight: "40vh",
    isExpanded: false,
    elevationExpanded: false,
    run: false,
    pref: false,
    ramainingOpen: false,
    sameDayOpen: false,
    numPettorali: 0,
    expandPlus: false,
    rating: {
      general: 0,
      paesaggio: 0,
      organizzazione: 0,
      ristori: 0,
      "pacco gara": 0,
      prezzo: 0,
    },
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    const paesaggio = Math.floor(Math.random() * 100);
    const organizzazione = Math.floor(Math.random() * 100);
    const ristori = Math.floor(Math.random() * 100);
    const pacco_gara = Math.floor(Math.random() * 100);
    const prezzo = Math.floor(Math.random() * 100);
    const general =
      (paesaggio + organizzazione + ristori + pacco_gara + prezzo) / 5;
    const numPettorali = Math.floor(Math.random() * 100);
    const numReviews = Math.floor(Math.random() * 100);
    this.setState({
      rating: {
        paesaggio,
        organizzazione,
        ristori,
        "pacco gara": pacco_gara,
        prezzo,
        general,
      },
      numPettorali,
      numReviews,
      recensioniPosition:
        !!document.getElementById("recensioni") &&
        document.getElementById("recensioni").offsetTop,
    });
  }

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  componentDidUpdate(oldProps) {
    if (this.props.match.params.id !== oldProps.match.params.id) {
      const paesaggio = Math.floor(Math.random() * 100);
      const organizzazione = Math.floor(Math.random() * 100);
      const ristori = Math.floor(Math.random() * 100);
      const pacco_gara = Math.floor(Math.random() * 100);
      const prezzo = Math.floor(Math.random() * 100);
      const general =
        (paesaggio + organizzazione + ristori + pacco_gara + prezzo) / 5;
      const numPettorali = Math.floor(Math.random() * 100);
      const numReviews = Math.floor(Math.random() * 100);
      this.setState({
        rating: {
          paesaggio,
          organizzazione,
          ristori,
          "pacco gara": pacco_gara,
          prezzo,
          general,
        },
        numPettorali,
        numReviews,
      });
    }
  }

  handleScroll = (event) => {
    if (window.scrollY >= this.state.recensioniPosition) {
      this.setState({
        recensioniStyle: "underline",
        percorsoStyle: "none",
      });
    } else {
      this.setState({
        recensioniStyle: "none",
        percorsoStyle: "underline",
      });
    }
    // this.setState({scrollPosition: window.scrollY})
  };

  expandImg = () => {
    this.setState({ isExpanded: "carousel4" });
    var temp = window.innerHeight - 50;
    this.setState({ imgHeight: "90vh" });
    this.setState({ isExpanded: true });
  };

  reduceImg = () => {
    this.setState({ isExpanded: "carousel3" });
    this.setState({ imgHeight: "40vh" });
    this.setState({ isExpanded: false });
  };

  expandImage = () => {
    if (!this.state.menuOpen) {
      this.setState({ isExpanded: true });
    } else {
      this.setState({ isExpanded: false });
    }
  };

  goToPage = (page) => {
    const race = races.find((race) => race.id == this.props.match.params.id);
    this.props.history.push({
      pathname: "/" + page,
      state: { path: this.props.match.url, name: race["nome"] },
    });
  };

  render() {
    const race = races.find((race) => race.id == this.props.match.params.id);

    return (
      <Fragment>
        {race && (
          <Fragment>
            <Header {...this.props} />
            {this.state.expandPlus && (
              <div>
                <div
                  style={{
                    backgroundColor: "rgba(256,256,256,0.92)",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    height: window.innerHeight,
                    width: window.innerWidth,
                    zIndex: 10,
                  }}
                  onClick={() =>
                    this.setState({ expandPlus: !this.state.expandPlus })
                  }
                ></div>

                <div
                  style={{
                    position: "fixed",
                    bottom: 80,
                    right: 0,
                    borderRadius: 16,
                    // backgroundColor: "rgb(149, 214, 10)",
                    backgroundColor: "transparent",
                    display: "flex",
                    flexDirection: "column",
                    margin: 16,
                    padding: "8px 12px",
                    zIndex: 300,
                    color: "rgb(149, 214, 10)",
                    // fontWeight: 'bold'
                    textDecoration: "underline",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      color: "rgb(149, 214, 10)",
                    }}
                  >
                    <div
                      style={{
                        padding: "8px 4px",
                        maxWidth: "26vw",
                        zIndex: "300",
                        paddingRight: 10,
                      }}
                      onClick={() => this.goToPage("Recensione")}
                    >
                      Scrivi una Recensione
                    </div>
                    <div style={circle}>
                      <FontAwesomeIcon
                        onClick={() => this.goToPage("Recensione")}
                        icon={faClipboard}
                        style={{ color: "rgb(149, 214, 10)", fontSize: 24 }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: 4
                    }}
                  >
                    <div
                      style={{
                        padding: "8px 4px",
                        maxWidth: "26vw",
                        zIndex: "300",
                        paddingRight: 10,
                      }}
                    >
                      Iscriviti alla gara
                    </div>
                    <div style={circle}>
                      <FontAwesomeIcon
                        onClick={() => this.goToPage("Recensione")}
                        icon={faClipboardCheck}
                        style={{ color: "rgb(149, 214, 10)", fontSize: 24 }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: 4
                    }}
                  >
                    <div
                      style={{
                        padding: "8px 4px",
                        maxWidth: "26vw",
                        zIndex: "300",
                        paddingRight: 10,
                      }}
                    >
                      Condividi
                    </div>
                    <div style={circle}>
                      <FontAwesomeIcon
                        onClick={() => this.goToPage("Recensione")}
                        icon={faShareAlt}
                        style={{ color: "rgb(149, 214, 10)", fontSize: 24 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                position: "fixed",
                bottom: 0,
                right: 0,
                width: 64,
                height: 64,
                borderRadius: 50,
                backgroundColor: "rgb(149, 214, 10)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: 16,
                zIndex: 10,
              }}
            >
              <FontAwesomeIcon
                onClick={() =>
                  this.setState({ expandPlus: !this.state.expandPlus })
                }
                className={this.state.expandPlus ? "plus rotate" : "plus"}
                icon={faPlus}
                style={{ color: "white", fontSize: 24 }}
              />
            </div>
            {this.state.isExpanded ? (
              <div
                style={{
                  position: "absolute",
                  top: "max(10vh,50px)",
                  right: "max(2vw,20px)",
                  color: "white",
                  zIndex: "300",
                  cursor: "pointer",
                }}
                onClick={this.reduceImg}
              >
                Chiudi
              </div>
            ) : (
              <div></div>
            )}
            <div
              style={{
                paddingTop: "max(50px,5vh)",
                height: 48,
                background: "lightgray",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                position: "fixed",
                width: window.innerWidth,
                zIndex: 5,
                color: "black",
              }}
            >
              <a
                href="#percorso"
                style={{
                  // flex: 1,
                  textAlign: "center",
                  fontSize: 16,
                  textDecoration: this.state.percorsoStyle || "none",
                  color: "black",
                }}
              >
                Percorso
              </a>
              <a
                href="#recensioni"
                style={{
                  // flex: 1,
                  textAlign: "center",
                  fontSize: 16,
                  textDecoration: this.state.recensioniStyle || "none",
                  color: "black",
                }}
              >
                Recensioni
              </a>
              <a
                href="#regolamento"
                style={{
                  // flex: 1,
                  textAlign: "center",
                  fontSize: 16,
                  textDecoration: "none",
                  color: "black",
                }}
              >
                Regolamento
              </a>
            </div>

            <div style={{ paddingTop: "max(98px,10vh)" }}>
              <Carousel
                onClickItem={this.expandImg}
                className={this.state.isExpanded}
                showThumbs={false}
                showStatus={false}
                showArrows={false}
              >
                {images.map((item, index) => (
                  <img
                    src={item.src}
                    alt=""
                    className={
                      !this.state.isExpanded
                        ? "imgCarousel"
                        : "imgCarousel expanded"
                    }
                  />
                ))}
              </Carousel>
            </div>

            <div
              style={{
                width: "95vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "2.5vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  width: "86vw",
                  paddingTop: 8,
                }}
              >
                <RaceDetail race={race} />

                <hr
                  style={{
                    width: "84vw",
                    border: " 1px solid gainsboro",
                    borderRadius: "2px",
                    marginTop: "4vh",
                    marginBottom: "3.5vh",
                  }}
                />

                <div
                  id="percorso"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: bigFontSize,
                      lineHeight: bigFontSize,
                    }}
                  >
                    Percorso
                  </div>
                  <div
                    className={this.state.isShrinked ? "textShrink6" : ""}
                    style={{
                      fontSize: mediumFontSize,
                      paddingTop: "1.6vh",
                      marginBottom: "0vh",
                    }}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged.Lorem Ipsum is simply dummy text of
                    the printing and typesetting industry. Lorem Ipsum has been
                    the industry's standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and scrambled
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged{" "}
                  </div>
                  <div
                    style={{
                      paddingLeft: "0vw",
                      fontWeight: "bold",
                      fontSize: "14px",
                      paddingTop: "4px",
                      paddingBottom: "2.6vh",
                    }}
                    onClick={() =>
                      this.setState({ isShrinked: !this.state.isShrinked })
                    }
                  >
                    {!this.state.isShrinked
                      ? "Mostra di meno"
                      : "Mostra di più"}
                  </div>
                </div>
                <img src={elevation} alt="" className={"elevationProfile"} />
                <hr
                  style={{
                    width: "84vw",
                    border: " 1px solid gainsboro",
                    borderRadius: "2px",
                    marginTop: "4vh",
                    marginBottom: "3.5vh",
                  }}
                />
                <div
                  id="recensioni"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      fontSize: bigFontSize,
                      lineHeight: bigFontSize,
                      fontWeight: "bold",
                    }}
                  >
                    Recensioni
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "2vh",
                      paddingBottom: "2vh",
                      paddingLeft: "0.5vw",
                    }}
                  >
                    <DotFeedback value={race.rating.general} />
                    <div
                      style={{
                        fontSize: smallFontSize,
                        lineHeight: smallFontSize,
                        paddingLeft: "2.5vw",
                        textDecoration: "underline",
                      }}
                    >
                      {feedback.length} Recensioni
                    </div>
                  </div>
                  <div style={{ paddingBottom: "2vh", paddingTop: "2vh" }}>
                    N° 3 su {regionRun.length} in Lombardia
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      paddingTop: "0vh",
                      width: "86vw",
                    }}
                  >
                    {feedbacktype.map((item, index) => (
                      <div
                        style={{
                          width: "80vw",
                          textTransform: "capitalize",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontSize: mediumFontSize,
                          lineHeight: mediumFontSize,
                          paddingTop: "1.6vh",
                        }}
                      >
                        {item.name}
                        <div style={{ display: "flex" }}>
                          <DotFeedback value={race.rating[item.name]} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <hr
                  style={{
                    width: "84vw",
                    border: " 1px solid gainsboro",
                    borderRadius: "2px",
                    marginTop: "4vh",
                    marginBottom: "3.5vh",
                  }}
                />

                <div
                  style={{
                    fontSize: bigFontSize,
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                  onClick={() => this.goToPage("Recensione")}
                >
                  Scrivi una recensione
                </div>

                <hr
                  style={{
                    width: "84vw",
                    border: " 1px solid gainsboro",
                    borderRadius: "2px",
                    marginTop: "4vh",
                    marginBottom: "3.5vh",
                  }}
                />

                <SingleRunFeedbackDisplay />

                <hr
                  style={{
                    width: "84vw",
                    border: " 1px solid gainsboro",
                    borderRadius: "2px",
                    marginTop: "4vh",
                    marginBottom: "3.5vh",
                  }}
                />

                <div>
                  <div
                    style={{
                      fontSize: bigFontSize,
                      fontWeight: "bold",
                      paddingBottom: "2.5vh",
                    }}
                  >
                    Vicino alla partenza
                  </div>
                  <Mappa height="45vh" width={"86vw"}></Mappa>
                </div>

                <hr
                  style={{
                    width: "84vw",
                    border: " 1px solid gainsboro",
                    borderRadius: "2px",
                    marginTop: "4vh",
                    marginBottom: "3.5vh",
                  }}
                />
              </div>
              {/* <div style={{marginTop:"-4.5vh"}}>
                            <RacePresentationSlider run dot="slick-dots" titleText ="Dello stesso organizzatore" {...this.props}/>
                        </div> */}
              <div
                style={{
                  width: "100vw",
                  marginTop: "1.5vh",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "2vh",
                }}
              >
                <SimpleSlider
                  run
                  dot="slick-dots"
                  titleText="GARE SIMILI"
                  altro="Vedi Di Più"
                  {...this.props}
                  similar={race}
                />
              </div>
            </div>
            <Footer />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default SingleRun;
